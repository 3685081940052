import { Box } from '@mui/joy'
import { BarLoader } from 'react-spinners'
import { colors } from './Theme'

export default function LoadingScreen() {
  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', mt: '20%' }}>
      <BarLoader color={colors.primary} width={150} />
    </Box>
  )
}
