import { Box, Typography } from '@mui/joy'
import { PestControlJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function PestControlDetails({ attrs }: { attrs: PestControlJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Property Type</Typography>
        <Typography level="title-sm">{attrs.propertyType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Floor Area (sqm)</Typography>
        <Typography level="title-sm">{attrs.floorArea}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Pest Identification</Typography>
        <Typography level="title-sm">{attrs.pestType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Severity</Typography>
        <Typography level="title-sm">{attrs.severity}</Typography>
      </Box>
    </Box>
  )
}
