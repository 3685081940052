import { Box, Grid, Typography } from '@mui/joy'
import CustomerLoginOptions from '../../components/CustomerLoginOptions'

export default function CustomerLoginPage() {
  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={4}>
        <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <CustomerLoginOptions />
        </Box>
      </Grid>
    </Grid>
  )
}
