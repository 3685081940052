import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_BUSINESS_PROFILE, UPDATE_BUSINESS_PROFILE } from '../../../graphql/business-profile-queries'
import { useContext, useState } from 'react'
import { GET_LEADS } from '../../../graphql/job-queries'
import { Box, Button, Grid, Typography } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import JobCategoryType from '../../../types/job-category-type'
import CategoryCoverage from '../../../components/CategoryCoverage'
import { LayoutContext } from '../../../layouts/LayoutContextProvider'

export default function OnboardingCategoryPage() {
  const navigate = useNavigate()
  const { showAlert } = useContext(LayoutContext)
  const { data: { businessProfile: { coveredCategories } } } = useSuspenseQuery(GET_BUSINESS_PROFILE)
  const [selectedCategories, setSelectedCategories] = useState<JobCategoryType[]>(coveredCategories)

  const [updateBusinessProfile, { loading }] = useMutation(UPDATE_BUSINESS_PROFILE, {
    variables: { attributes: { coveredCategoryIds: selectedCategories.map(({id}) => id) } },
    refetchQueries: [{ query: GET_LEADS }],
    onCompleted: () => navigate('/business/onboarding/profile')
  })

  function handleNext() {
    if (selectedCategories.length > 0) {
      updateBusinessProfile()
    } else {
      showAlert('Please select at least 1 category', 'danger')
    }
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <Box sx={{ mb: 3, display: 'flex', gap: 1, justifyContent: 'space-between' }}>
          <Button color="neutral" variant="outlined" onClick={() => navigate('/business/onboarding/location')}>Back</Button>
          <Button color="neutral" variant="outlined" disabled={loading} onClick={handleNext}>Next</Button>
        </Box>
      </Grid>
      <Grid xs={12} sx={{ mb: 1 }}>
        <Typography level="h3">Covered Categories</Typography>
        <Typography level="body-sm">Piliin ang mga categories na applicable sa iyo. Ang bawat post ni client ay mayroong isang category na ima-match sa business mo.</Typography>
        <Grid container spacing={1} mt={1}>
          <Grid xs={12}>
            <CategoryCoverage
              categories={selectedCategories}
              onCategoriesChange={setSelectedCategories}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
