import { Box, Typography } from '@mui/joy'
import { AirconJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function AirconDetails({ attrs }: { attrs: AirconJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Number of Units</Typography>
        <Typography level="title-sm">{attrs.numberOfUnits}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Service Needed</Typography>
        <Typography level="title-sm">{attrs.serviceType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Aircon Type</Typography>
        <Typography level="title-sm">{attrs.airconType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Brand</Typography>
        <Typography level="title-sm">{attrs.brand}</Typography>
      </Box>
    </Box>
  )
}
