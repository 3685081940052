//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box } from '@mui/joy'
import { FallbackProps } from 'react-error-boundary'

export default function ErrorFallback({ error }: FallbackProps) {
  console.error(error)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 20, height: '100vh' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ fontSize: '50px' }}>Something went wrong</Box>
        <Box>We are notified of this error and we&apos;ll be fixing this!</Box>
      </Box>
    </Box>
  )
}
