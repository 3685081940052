//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box } from '@mui/joy'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { colors } from '../layouts/Theme'
import { BusinessAvailability } from '../types/estimate-type'

const availabilityMap = {
  [BusinessAvailability.Immediately]: "Immediately",
  [BusinessAvailability.Weeks]: "1 to 4 weeks",
  [BusinessAvailability.Months]: "2 to 6 months",
  [BusinessAvailability.ScopeBased]: "Depends on work scope",
}

export function formatAvailability(availability: BusinessAvailability) {
  return availabilityMap[availability]
}

export default function DisplayAvailability({ availability }: { availability: BusinessAvailability }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '8px', height: '30px' }}>
      <AccessTimeIcon sx={{ mr: '5px', fontSize: '20px', color: colors.gray1 }}/>{formatAvailability(availability)}
    </Box>
  )
}
