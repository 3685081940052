import BusinessUserType from "./business-user-type"
import JobType from "./job-type"
import Project from "./project-type"
import { BusinessReviewType } from "./review-type"

export enum BusinessAvailability {
  Immediately = "Immediately",
  Weeks = "Weeks",
  Months = "Months",
  ScopeBased = "ScopeBased",
}

export interface EstimateAttributes {
  jobId: string
  estimateMin: number
  estimateMax: number
  availability: BusinessAvailability
  details: string
}

export default interface EstimateType {
  id: string
  jobId: string
  job: JobType
  businessUser: BusinessUserType
  businessProjects: Project[]
  businessReviews: BusinessReviewType[]
  estimateMin: number
  estimateMax: number
  availability: BusinessAvailability
  details: string
  createdAt: string
}
