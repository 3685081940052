import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_BUSINESS_PROFILE, UPDATE_BUSINESS_PROFILE } from '../../../graphql/business-profile-queries'
import { useContext, useState } from 'react'
import LocationCoverage, { LocationOption, convertCitiesToLocations } from '../../../components/LocationCoverage'
import { GET_LEADS } from '../../../graphql/job-queries'
import { Box, Button, Grid, Typography } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { LayoutContext } from '../../../layouts/LayoutContextProvider'

export default function OnboardingLocationPage() {
  const navigate = useNavigate()
  const { showAlert } = useContext(LayoutContext)
  const { data: { businessProfile: { coveredLocations } } } = useSuspenseQuery(GET_BUSINESS_PROFILE)
  const [coveredLocationIds, setCoveredLocationIds] = useState<string[]>(coveredLocations.map(({id}) => id))
  const [selectedLocations, setSelectedLocations] = useState<LocationOption[]>(convertCitiesToLocations(coveredLocations))

  const [updateBusinessProfile, { loading }] = useMutation(UPDATE_BUSINESS_PROFILE, {
    variables: { attributes: { coveredLocationIds } },
    refetchQueries: [{ query: GET_LEADS }],
    onCompleted: () => navigate('/business/onboarding/categories')
  })

  function handleNext() {
    if (selectedLocations.length > 0) {
      updateBusinessProfile()
    } else {
      showAlert('Please select at least 1 location', 'danger')
    }
  }

  return (
    <Grid xs={12} sx={{ mb: 1 }}>
      <Typography level="h3">Covered Locations</Typography>
      <Typography level="body-sm">Piliin ang mga lugar na sakop ng iyong operation. Ang leads na matatanggap mo ay base sa location na ipo-post ni client.</Typography>
      <Grid container spacing={1} mt={1}>
        <Grid xs={12} sm={9} md={7} lg={7}>
          <LocationCoverage
            autoFocus
            locations={selectedLocations}
            onCityIdsChange={setCoveredLocationIds}
            onLocationsChange={setSelectedLocations}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row-reverse' }}>
        <Button color="neutral" variant="outlined" disabled={loading} onClick={handleNext}>Next</Button>
      </Box>
    </Grid>
  )
}
