import { Box, Grid, Typography } from '@mui/joy'
import { Outlet } from 'react-router-dom'

export default function OnboardingLayout() {
  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Welcome to HomeMaster 👋</Typography>
      </Grid>
      <Grid xs={12} lg={9}>
        <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px' }}>
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  )
}
