//@ts-expect-error: This is needed by Rails
import React, { useContext, useState } from 'react'
import { Box, Button, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, Textarea } from '@mui/joy'
import ImageUploadButton from './ImageUploadButton'
import { ProjectAttributes } from '../types/project-type'
import { useForm } from 'react-hook-form'
import { fileListFromArray } from '../utils/general-utils'
import ImageUploadPreview from './ImageUploadPreview'
import { useMutation } from '@apollo/client'
import { CREATE_PROJECT, GET_PROJECTS } from '../graphql/project-queries'
import { LayoutContext } from '../layouts/LayoutContextProvider'

export default function AddProjectModal({ open, setOpen }: { open: boolean, setOpen: (val: boolean) => void }) {
  const [imagePreviews, setImagePreviews] = useState<string[]>([])
  const { register, handleSubmit, getValues, setValue } = useForm<ProjectAttributes>()

  const { showAlert } = useContext(LayoutContext)
  const [createProject, { loading: formSubmitting }] = useMutation(CREATE_PROJECT)

  function removeImage(index: number) {
    const currentFiles = getValues('fileList')
    if (!currentFiles) return

    const newImagePreviews = imagePreviews.filter((_, i) => i !== index)
    const newFiles = Array.from(currentFiles).filter((_, i) => i !== index)

    setValue('fileList', fileListFromArray(newFiles))
    setImagePreviews(newImagePreviews)
  }

  function onSubmit(data: ProjectAttributes) {
    createProject({
      variables: { ...data },
      refetchQueries: [{ query: GET_PROJECTS }],
      onCompleted: () => {
        showAlert('Your portfolio has been added')
        setOpen(false)
      }
    })
  }

  return (
    <Modal open={open} onClose={() => setOpen(false) } disableRestoreFocus>
      <ModalDialog sx={{ width: { xs: '90%', sm: '70%', md: '60%' } }}>
        <DialogTitle sx={{ mb: 3 }}>
          Add New Portfolio
          <ModalClose />
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.stopPropagation()
              handleSubmit(onSubmit)(e)
            }}
          >
            <FormControl>
              <FormLabel required>Name</FormLabel>
              <Input autoFocus {...register('name')} placeholder="Name or summary of this portfolio" />
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel required>Description</FormLabel>
              <Textarea minRows={10} {...register('description')} placeholder="Describe this portfolio in details" />
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <ImageUploadButton setImagePreviews={setImagePreviews} onChange={(files) => setValue('fileList', fileListFromArray(files))} />
              <ImageUploadPreview imagePreviews={imagePreviews} onRemove={removeImage} />
            </Box>
            <Button sx={{ mt: 5 }} type="submit" disabled={formSubmitting}>Add Portfolio</Button>
            <Button sx={{ mt: 5, ml: 1 }} color="neutral" variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          </form>
        </DialogContent>
      </ModalDialog>
    </Modal>
  )
}
