import { extendTheme } from '@mui/joy/styles'

export const fontFamilies ={
  title: 'Karma, Helvetica, serif',
}

export const colors = {
  primary: '#F89A33',
  primaryMuted: '#FBF0E4',
  primaryHover: '#E58E2F',
  primaryActive: '#CE8029',
  gray1: '#E9E1DB',
  gray2: '#BDB8B4',
  gray3: '#777B82',
  gray2Hover: '#B1ADA9',
  gray2Active: '#8B8784',
  background: '#F2F0EE',
  fontColor: '#1A1816',
  green: '#4CC249',
  red: '#F83333',
  white: '#FFFFFF',
}

export const fontSizes = {
  large: '18px',
  regular: '16px',
  sm: '14px',
  xs: '12px',
}

export const appTheme = extendTheme({
  'typography': {
    'h1': {
      color: colors.fontColor,
      fontSize: '26px'
    },
    'h2': {
      fontSize: '24px'
    },
    'h3': {
      fontSize: '20px'
    }
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: colors.primary,
          solidHoverBg: colors.primaryHover,
          solidActiveBg: colors.primaryActive,
        }
      }
    }
  },
  components: {
    JoyBreadcrumbs: {
      styleOverrides: {
        root: {
          paddingLeft: '0',
          'a': {
            color: 'var(--joy-palette-neutral-600)',
            textDecoration: 'none'
          },
          'a:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    JoyFormLabel: {
      styleOverrides: {
        root: {
          color: colors.fontColor
        }
      }
    },
    JoyLink: {
      styleOverrides: {
        root: {
          color: colors.fontColor,
          textDecoration: 'underline',
        }
      }
    },
    JoySelect: {
      styleOverrides: {
        root: {
          padding: '7px 15px',
          borderRadius: '8px',
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          padding: '7px 15px',
          borderRadius: '8px',
          '--Textarea-focusedThickness': 0,
          '&.Mui-focused': {
            borderColor: colors.primary,
          },
        },
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: {
          padding: '3px 10px',
          borderRadius: '8px',
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: '7px 10px 7px',
          borderRadius: '8px',
          fontSize: fontSizes.sm,
          ...(ownerState.color === 'neutral' && {
            backgroundColor: 'white',
          })
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          padding: '7px 15px',
          borderRadius: '8px',
          '--Input-focusedThickness': 0,
          '&.Mui-focused': {
            borderColor: colors.primary,
          }
        },
      }
    }
  }
})
