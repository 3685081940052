import { Box, Typography } from '@mui/joy'
import { CleaningJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function CleaningDetails({ attrs }: { attrs: CleaningJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Cleaning Type</Typography>
        <Typography level="title-sm">{attrs.cleaningType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Focus Area</Typography>
        <Typography level="title-sm">{attrs.focusArea}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Area Size</Typography>
        <Typography level="title-sm">{attrs.areaSize}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Condition</Typography>
        <Typography level="title-sm">{attrs.condition}</Typography>
      </Box>
    </Box>
  )
}
