//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import { Box, Checkbox, FormControl, Grid, Input } from '@mui/joy'
import JobCategoryType from '../types/job-category-type'
import Fuse from 'fuse.js'
import { colors, fontFamilies, fontSizes } from '../layouts/Theme'
import { renderIcon } from '../utils/render-utils'
import { useSuspenseQuery } from '@apollo/client'
import { GET_JOB_CATEGORIES } from '../graphql/job-category-queries'

interface Props {
  onCategoriesChange: (val: JobCategoryType[]) => void
  categories: JobCategoryType[]
  autoFocus?: boolean
}

export default function CategoryCoverage({ categories, onCategoriesChange, autoFocus = false }: Props) {
  const selectedCategoryIds = categories.map(({id}) => id)
  const { data: { jobCategories } } = useSuspenseQuery(GET_JOB_CATEGORIES)
  const fuse = new Fuse(jobCategories, { keys: ['name'], threshold: 0.4 })
  const [categoryOptions, setCategoryOptions] = useState<JobCategoryType[]>(jobCategories)

  return (
    <Box>
      <Grid xs={12}>
        <FormControl>
          <Input
            sx={{
              '&.Mui-focused': {
                borderColor: 'var(--joy-palette-neutral-300)',
              }
            }}
            type="text"
            autoFocus={autoFocus}
            placeholder="Search and select categories"
            onChange={({ target: { value }}) => {
              if (value) {
                setCategoryOptions(fuse.search(value).map(({ item }) => item))
              } else {
                setCategoryOptions(jobCategories)
              }
            }}
          />
        </FormControl>
      </Grid>
      <Grid container spacing={2} mt={2}>
        {categoryOptions.map((category) => {
          const checked = selectedCategoryIds.includes(category.id)
          return (
            <Grid xs={12} sm={6} md={4} key={category.id}>
              <Checkbox
                sx={{
                  '--Checkbox-actionRadius': '20px',
                  '--variant-outlinedBorder': 'var(--joy-palette-neutral-200)',
                  '.MuiCheckbox-action.Mui-checked': {
                    bgcolor: colors.white,
                    border: `1px solid ${colors.primary}`
                  },
                  p:  { xs: '10px 15px', sm: '20px' },
                  width: '100%',
                  height: { xs: '120px', sm: '150px', md: '170px' },
                  borderRadius: '20px',
                }}
                checked={checked}
                onChange={({ target: { checked }}) => {
                  if (checked) {
                    onCategoriesChange([...categories, category])
                  } else {
                    onCategoriesChange(categories.filter(({ id }) => id !== category.id))
                  }
                }}
                disableIcon
                label={
                  <Box sx={{ color: colors.primary }}>
                    <Box sx={{ mb: 1 }}>{renderIcon(category.slug)}</Box>
                    <Box sx={{ fontFamily: fontFamilies.title, fontWeight: 700, fontSize: fontSizes.large, color: colors.fontColor }}>{category.name}</Box>
                    <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2 }}>{category.description}</Box>
                  </Box>
                }
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
