import { Box, FormControl, Grid, Tab, TabList, TabPanel, Tabs, Typography, tabClasses } from '@mui/joy'
import { useContext, useState } from 'react'
import JobCategoryType from '../types/job-category-type'
import JobCategoryAutocomplete from '../components/JobCategoryAutocomplete'
import JobCategories from '../components/JobCategories'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import { useNavigate } from 'react-router-dom'
import BusinessLoginOptions from '../components/BusinessLoginOptions'
import CustomerLoginOptions from '../components/CustomerLoginOptions'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import HandymanIcon from '@mui/icons-material/Handyman'

export default function HomePage() {
  const [jobCategory] = useState<JobCategoryType | null>(null)
  const [homeSelected, setHomeSelected] = useState(true)
  const { showAlert } = useContext(LayoutContext)
  const navigate = useNavigate()

  function navigateToNewJobPage(jobCategorySlug = jobCategory?.slug) {
    if (jobCategorySlug) {
      navigate(`/get_estimates/${jobCategorySlug}`)
    } else {
      showAlert('Please select a category to get started', 'danger')
    }
  }

  function handleJobCategoryClick(jobCategory: JobCategoryType | null) {
    navigateToNewJobPage(jobCategory?.slug)
  }

  return (
    <>
      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: { xs: '100%', md: '60%' }, p: { xs: '0 25px', md: 0 } }}>
          <Typography sx={{ mb: 4, textAlign: 'center', fontSize: { xs: '30px', md: '40px' }, lineHeight: '1em', fontWeight: 500 }}>
            Get free estimates for your home construction and maintenance needs
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
        <Tabs
          variant="outlined"
          defaultValue={0}
          onChange={(_e, value) => setHomeSelected(value === 0)}
          sx={{ width: { xs: '100%', md: '50%', lg: '40%' }, borderRadius: 'lg', overflow: 'auto', border: 0, bgcolor: 'transparent' }}
        >
          <TabList
            disableUnderline
            tabFlex={1}
            sx={{
              p: 0.75,
              gap: 1,
              borderRadius: 'xl',
              bgcolor: 'background.level1',
              fontSize: { xs: '14px', md: '16px' },
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.surface',
              },
            }}
          >
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              <HomeIcon sx={{ fontSize: '20px' }}/>
            </Tab>
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              <PersonIcon sx={{ fontSize: '20px' }} /> Login
            </Tab>
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              <HandymanIcon sx={{ fontSize: '20px', mr: '3px' }} /> Business
            </Tab>
          </TabList>
          <TabPanel value={0} sx={{ p: '1px', pt: '20px' }}>
            <FormControl sx={{ width: '100%', mt: 1, '.MuiAutocomplete-root': { p: '7px 20px' } }}>
              <JobCategoryAutocomplete
                onValueChange={(value) => {
                  const category = value as JobCategoryType
                  navigateToNewJobPage(category.slug)
                }}
                value={jobCategory}
                autoFocus={false}
              />
            </FormControl>
          </TabPanel>
          <TabPanel value={1} sx={{ p: 0, pt: '30px' }}>
            <Box sx={{ bgcolor: 'white', p: '20px', borderRadius: '20px' }}>
              <CustomerLoginOptions />
            </Box>
          </TabPanel>
          <TabPanel value={2} sx={{ p: 0, pt: '30px' }}>
            <Box sx={{ bgcolor: 'white', p: '20px', borderRadius: '20px' }}>
              <BusinessLoginOptions />
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
      {homeSelected &&
        <Grid xs={12} sx={{ mt: { sm: 3, md: 5 } }}>
          <JobCategories selectedJobCategory={jobCategory} onClick={handleJobCategoryClick}/>
        </Grid>}
    </>
  )
}
