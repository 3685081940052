import { Grid, Typography } from '@mui/joy'
import { CURRENT_BUSINESS_REVIEWS } from '../../graphql/review-queries'
import { useSuspenseQuery } from '@apollo/client'
import BusinessReviews from '../../components/BusinessReviews'

export default function BusinessReviewsPage() {
  const { data: { currentBusinessReviews } } = useSuspenseQuery(CURRENT_BUSINESS_REVIEWS)

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Reviews</Typography>
      </Grid>
      <Grid xs={12} md={6}>
        <BusinessReviews businessReviews={currentBusinessReviews} />
      </Grid>
    </Grid>
  )
}
