import { useSuspenseQuery } from "@apollo/client"
import JobCategoryType from "../types/job-category-type"
import { GET_JOB_CATEGORIES } from "../graphql/job-category-queries"
import { Grid } from "@mui/joy"
import CategoryCard from "./CategoryCard"

type JobCategoriesProps = {
  selectedJobCategory: JobCategoryType | null,
  onClick: (jobCategory: JobCategoryType | null) => void
}

export default function JobCategories({ selectedJobCategory, onClick }: JobCategoriesProps) {
  const { data } = useSuspenseQuery(GET_JOB_CATEGORIES)

  return (
    <Grid container spacing={{ xs: 2, lg: 4 }} sx={{ mt: 2 }}>
      {data.jobCategories.map((jobCategory) => (
        <Grid xs={6} md={4} lg={3} key={jobCategory.id}>
          <CategoryCard
            jobCategory={jobCategory}
            selected={jobCategory.id === selectedJobCategory?.id}
            onClick={onClick}
          />
        </Grid>
      ))}
    </Grid>
  )
}
