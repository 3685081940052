import { Box, FormControl, FormLabel, Select, Option } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { ElectricalJobAttributes, JobAttributes } from '../../types/job-type'

export default function ElectricalForm({ control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: ElectricalJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Electrical Service</FormLabel>
        <Controller
          name="categoryAttrsValues.electricalService"
          defaultValue={attrs?.electricalService}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What electrical service do you need?"
            >
              <Option value="Installation">Installation</Option>
              <Option value="Repairs">Repairs</Option>
              <Option value="Troubleshooting">Troubleshooting</Option>
              <Option value="New Construction Wiring">New Construction Wiring</Option>
              <Option value="Safety Inspections">Safety Inspections</Option>
              <Option value="Energy Efficiency Assessments">Energy Efficiency Assessments</Option>
              <Option value="Grounding and Bonding">Grounding and Bonding</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Scope</FormLabel>
        <Controller
          name="categoryAttrsValues.scope"
          defaultValue={attrs?.scope}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Scope of the work"
            >
              <Option value="Single room">Single room</Option>
              <Option value="Multiple rooms">Multiple rooms</Option>
              <Option value="Entire house">Entire house</Option>
              <Option value="Outdoor area">Outdoor area</Option>
              <Option value="Specific equipment">Specific equipment</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Electrical Issue</FormLabel>
        <Controller
          name="categoryAttrsValues.issue"
          defaultValue={attrs?.issue}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Select the electrical issue"
            >
              <Option value="Flickering lights">Flickering lights</Option>
              <Option value="Tripped breakers">Tripped breakers</Option>
              <Option value="Power outages">Power outages</Option>
              <Option value="Frequent circuit breaker trips">Frequent circuit breaker trips</Option>
              <Option value="Overheating outlets or switches">Overheating outlets or switches</Option>
              <Option value="Burning smell">Burning smell</Option>
              <Option value="Inconsistent power supply">Inconsistent power supply</Option>
              <Option value="Inconsistent power supply">Inconsistent power supply</Option>
              <Option value="Spark outlets">Spark outlets</Option>
              <Option value="Fused or melted wiring">Fused or melted wiring</Option>
              <Option value="Faulty switches">Faulty switches</Option>
              <Option value="Increased electric bills">Increased electric bills</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
