//@ts-expect-error: This is needed by Rails
import React, { useContext, useState } from 'react'
import { Grid, FormLabel, FormControl, Input, Textarea, Button, Select, Option, RadioGroup, Radio, Sheet, Box, Typography } from '@mui/joy'
import JobType, { AirconJobAttributes, CleaningJobAttributes, ConstructionJobAttributes, ElectricalJobAttributes, JobAttributes, JobTimeline, ModularCabinetJobAttributes, PestControlJobAttributes, RenovationJobAttributes } from '../types/job-type'
import { colors } from '../layouts/Theme'
import NumericFormatAdapter from './NumericFormatAdapter'
import { Controller, useForm } from 'react-hook-form'
import ImageUploadPreview from './ImageUploadPreview'
import ImageUploadButton from './ImageUploadButton'
import { fileListFromArray } from '../utils/general-utils'
import CategoryIcon from './CategoryIcon'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import JobCategoryType from '../types/job-category-type'
import CityType from '../types/city-type'
import LocationAutocomplete from './LocationAutocomplete'
import ConstructionForm from './category_templates/ConstructionForm'
import { isEmpty } from 'lodash'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import { allCategoryAttributesHaveValues } from '../utils/job-utils'
import AirconForm from './category_templates/AirconForm'
import PestControlForm from './category_templates/PestControlForm'
import ModularCabinetForm from './category_templates/ModularCabinetForm'
import RenovationForm from './category_templates/RenovationForm'
import dayjs from 'dayjs'
import LeadCard from './LeadCard'
import { useMutation } from '@apollo/client'
import { GET_JOBS, UPDATE_JOB } from '../graphql/job-queries'
import { useNavigate } from 'react-router-dom'
import CleaningForm from './category_templates/CleaningForm'
import ElectricalForm from './category_templates/ElectricalForm'

type BudgetType = 'withoutBudget' | 'withBudget'

interface Props {
  onSubmit: (data: JobAttributes) => void
  saving: boolean
  submitLabel: string
  job?: JobType
  jobCategory: JobCategoryType
  city?: CityType
}

export default function JobForm({ 
  onSubmit, 
  saving = false, 
  submitLabel,
  jobCategory,
  city,
  job
}: Props) {
  const initialBudgetType = job?.budgetMin ? 'withBudget' : 'withoutBudget'
  const [previewMode, setPreviewMode] = useState(false)
  const [imagePreviews, setImagePreviews] = useState<string[]>([])
  const [selectedCity, setSelectedCity] = useState<CityType | null>(null)
  const [budgetType, setBudgetType] = useState<BudgetType>(initialBudgetType)
  const { register, handleSubmit, control, getValues, setValue } = useForm<JobAttributes>({
    defaultValues: {
      description: job?.description,
    }
  })
  const genericCategory = !['construction', 'aircon-services', 'renovation', 'modular-cabinet', 'pest-control'].includes(jobCategory.slug)
  const { showAlert } = useContext(LayoutContext)
  const [jobAttributes, setJobAttributes] = useState({} as JobAttributes)
  const finalCity = city || job?.city || selectedCity
  const summary = `${jobCategory.name} job in ${finalCity?.name}, ${finalCity?.provinceName}`
  const navigate = useNavigate()

  const [updateJob, { loading: reopening }] = useMutation(UPDATE_JOB, {
    refetchQueries: [{ query: GET_JOBS }]
  })

  function reopenJob() {
    if (job) {
      updateJob({
        variables: {
          id: job.id,
          attributes: {
            status: 'active',
            bumpedAt: dayjs().toISOString(),
          }
        },
        onCompleted: () => {
          showAlert('Your job has been reopened. You will receive estimates for this job again.')
          navigate('/customer/jobs')
        }
      })
    }
  }

  function removeImage(index: number) {
    const currentFiles = getValues('fileList')
    if (!currentFiles) return

    const newImagePreviews = imagePreviews.filter((_, i) => i !== index)
    const newFiles = Array.from(currentFiles).filter((_, i) => i !== index)

    setValue('fileList', fileListFromArray(newFiles))
    setImagePreviews(newImagePreviews)
  }

  function saveJob(formData: JobAttributes) {
    validateForm(formData)
    onSubmit(jobAttributes)
  }

  function submitJob() {
    onSubmit(jobAttributes)
  }

  function validateForm(formData: JobAttributes) {
    if (!isEmpty(formData.description) && (!isEmpty(formData.cityId) || !isEmpty(city)) && allCategoryAttributesHaveValues(jobCategory.slug, formData.categoryAttrsValues)) {
      setJobAttributes(formData)
      if (!job) { setPreviewMode(true) }
    } else {
      showAlert('Please complete all the required fields', 'danger')
    }
  }

  return (
    <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
      {!previewMode &&
        <>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ display: 'flex' }}>
              <CategoryIcon categorySlug={jobCategory.slug} />
            </Box>
            <Box>
              <Typography level="title-lg">{jobCategory.name}</Typography>
              {city &&
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOnIcon sx={{ fontSize: '20px', ml: '-5px', color: colors.gray2 }}/>
                  <Typography level="body-sm">{city.name}</Typography>
                </Box>}
            </Box>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {!city &&
                <>
                  <Grid xs={12} md={6}>
                    <FormControl sx={{ mt: 2, mb: 1 }}>
                      <FormLabel required>Location</FormLabel>
                      <Controller
                        name="cityId"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <LocationAutocomplete
                            onValueChange={(val) => {
                              setSelectedCity(val as CityType)
                              onChange((val as CityType).id)
                            }}
                            value={selectedCity}
                            autoFocus
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={0} md={6} sx={{ display: { xs: 'none', md: 'block' }}} />
                </>}
              <Grid xs={12} md={6}>
                <FormControl sx={{ mt: city ? 2 : 0, mb: 1 }}>
                  <FormLabel>Timeline</FormLabel>
                  <Controller
                    name="timeline"
                    control={control}
                    defaultValue={job?.timeline || JobTimeline.asap}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        onChange={(_e, val) => onChange(val)}
                      >
                        <Option value={JobTimeline.asap}>As soon as possible</Option>
                        <Option value={JobTimeline.inWeeks}>1-3 weeks from now</Option>
                        <Option value={JobTimeline.inMonths}>1-3 months from now</Option>
                        <Option value={JobTimeline.inHalfYear}>6-12 months from now</Option>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <FormLabel sx={{ mb: 1 }}>
                  Budget Option
                </FormLabel>
                <RadioGroup
                  orientation="horizontal"
                  value={budgetType}
                  onChange={({ target: { value }}) => setBudgetType(value as BudgetType)}
                  sx={{ gap: 1.5 }}
                >
                  {["withoutBudget", "withBudget"].map((value) => (
                    <Sheet
                      key={value}
                      sx={{
                        p: '7px 15px',
                        borderRadius: 'md',
                        width: {
                          xs: '100%',
                          md: '50%',
                        }
                      }}
                    >
                      <Radio
                        overlay
                        disableIcon
                        label={value === 'withBudget' ? 'I have a budget in mind' : "I don't have a budget in mind"}
                        value={value}
                        slotProps={{
                          label: ({ checked }) => ({
                            sx: {
                              fontWeight: checked ? 500 : 'initial',
                              color: checked ? colors.primary : colors.fontColor
                            }
                          }),
                          action: ({ checked }) => ({
                            sx: {
                              '&:hover': {
                                borderColor: colors.primary,
                                backgroundColor: '#FFF',
                              },
                              ...(checked && {
                                inset: -1,
                                '--variant-borderWidth': '1px',
                                '&&': {
                                  borderColor: colors.primary,
                                }
                              })
                            }
                          })
                        }}
                      />
                    </Sheet>
                  ))}
                </RadioGroup>
              </Grid>
              {budgetType === "withBudget" &&
                <>
                  <Grid xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Lower range</FormLabel>
                      <Controller
                        name="budgetMin"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <Input
                            startDecorator="₱"
                            defaultValue={job?.budgetMin}
                            onChange={(e) => onChange(+e.target.value)}
                            slotProps={{
                              input: {
                                component: NumericFormatAdapter,
                              },
                            }}
                            placeholder="Enter your lowest budget"
                            autoFocus
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Higher range</FormLabel>
                      <Controller
                        name="budgetMax"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <Input
                            defaultValue={job?.budgetMax}
                            startDecorator="₱"
                            onChange={(e) => onChange(+e.target.value)}
                            slotProps={{
                              input: {
                                component: NumericFormatAdapter,
                              },
                            }}
                            placeholder="Enter your max budget"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </>}
              <Grid xs={12} md={6} lg={4}>
                <FormLabel sx={{ mb: 1 }}>Images</FormLabel>
                <ImageUploadButton setImagePreviews={setImagePreviews} onChange={(files) => setValue('fileList', fileListFromArray(files))} />
                <ImageUploadPreview imagePreviews={imagePreviews} onRemove={removeImage} />
              </Grid>
              <Grid xs={12}>
                {jobCategory.slug === 'construction' &&
                  <ConstructionForm register={register} control={control} attrs={job?.categoryAttrsValues as unknown as ConstructionJobAttributes} />}
                {jobCategory.slug === 'aircon-services' &&
                  <AirconForm register={register} control={control} attrs={job?.categoryAttrsValues as unknown as AirconJobAttributes} />}
                {jobCategory.slug === 'pest-control' &&
                  <PestControlForm register={register} control={control} attrs={job?.categoryAttrsValues as unknown as PestControlJobAttributes} />}
                {jobCategory.slug === 'modular-cabinet' &&
                  <ModularCabinetForm register={register} control={control} attrs={job?.categoryAttrsValues as unknown as ModularCabinetJobAttributes} />}
                {jobCategory.slug === 'renovation' &&
                  <RenovationForm register={register} control={control} attrs={job?.categoryAttrsValues as unknown as RenovationJobAttributes} />}
                {jobCategory.slug === 'cleaning-services' &&
                  <CleaningForm register={register} control={control} attrs={job?.categoryAttrsValues as unknown as CleaningJobAttributes} />}
                {jobCategory.slug === 'electrical' &&
                  <ElectricalForm register={register} control={control} attrs={job?.categoryAttrsValues as unknown as ElectricalJobAttributes} />}
                <FormControl sx={{ mt: 2, width: { xs: '100%', md: '50%' }}}>
                  <FormLabel>Description</FormLabel>
                  <Textarea autoFocus={city && genericCategory ? true : false} required {...register("description")} placeholder="Mas madaming businesses ang magbibigay ng estimates kung detalydo ang post mo." minRows={6}/>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                {job ?
                  <Box>
                    <Button onClick={handleSubmit(saveJob)} sx={{ mr: '10px' }}>{submitLabel}</Button>
                    {job.status === 'archived' &&
                      <Button color="neutral" disabled={reopening} variant="outlined" onClick={() => reopenJob()} sx={{ mr: '10px' }}>
                        Reopen Job
                      </Button>}
                  </Box>:
                  <Button onClick={handleSubmit(validateForm)} sx={{ mr: '10px' }}>Next</Button>}
              </Grid>
            </Grid>
          </form>
        </>}
      {previewMode && !job &&
        <Box>
          <Typography level="h2">Preview</Typography>
          <Box sx={{ borderRadius: '20px', my: 2, border: `1px solid ${colors.gray1}`, width: { xs: '100%', md: '70%' } }}>
            <LeadCard
              preview
              job={{
                id: 'preview',
                jobCategory: jobCategory,
                summary,
                timeline: jobAttributes.timeline,
                budgetMin: jobAttributes.budgetMin,
                budgetMax: jobAttributes.budgetMax,
                status: 'active',
                createdAt: dayjs().toString(),
                images: [],
                estimatesCount: 0
              } as unknown as JobType}
            />
          </Box>
          <Typography level="title-lg" sx={{ mb: 1 }}>Notes</Typography>
          <Typography level="body-sm">
            <ul>
              <li style={{ marginBottom: '5px' }}>Your job will be posted anonymously. Hindi makikita ng businesses ang iyong name at contact details</li>
              <li>Only business users can see your job. Hindi ito makikita ng ibang clients sa platform</li>
            </ul>
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => submitJob()} disabled={saving}>{submitLabel}</Button>
            <Button variant="outlined" color="neutral" onClick={() => setPreviewMode(false)}>Back</Button>
          </Box>
        </Box>}
    </Box>
  )
}
