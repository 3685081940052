import { colors } from '../layouts/Theme'
import { getCSRFToken } from '../utils/general-utils'
import AppleIcon from '@mui/icons-material/Apple'

export default function AppleLogin({ userType }: { userType: 'business' | 'customer' }) {
  const csrfToken = getCSRFToken()
  const businessMode = userType === 'business'

  return (
    <form action={`/auth/apple?type=${userType}`} method="post">
      <input type="hidden" name="authenticity_token" value={csrfToken || ''} />
      <button type="submit" className="apple-login-btn"
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '4px',
          padding: '4px 8px',
          alignItems: 'center',
          height: '45px',
          backgroundColor: `${businessMode ? '#FFFFFF' : colors.fontColor}`,
          border: `1px solid ${businessMode ? colors.gray2 : '#000000'}`,
          color: `${businessMode ? colors.fontColor : '#FFFFFF'}`,
        }}
      >
        <AppleIcon sx={{ color: businessMode ? colors.fontColor : '#FFFFFF' }} />
        <span style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>Continue with Apple</span>
      </button>
    </form>
  )
}
