import React from 'react'
import { Box, Grid, Typography } from '@mui/joy'
import Notification from '../types/notification-type'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import CircleIcon from '@mui/icons-material/Circle'
import { colors, fontSizes } from '../layouts/Theme'
import { useMutation } from '@apollo/client'
import { MARK_NOTIFICATION_AS_READ } from '../graphql/notification-queries'
import { GET_CURRENT_BUSINESS_USER, GET_CURRENT_CUSTOMER } from '../graphql/user-queries'
import { renderIcon } from '../utils/render-utils'
import BusinessLogo from './BusinessLogo'
import NotificationsIcon from '@mui/icons-material/Notifications'
import RateReviewIcon from '@mui/icons-material/RateReview'

export default function NotificationsList({ notifications, type }: { notifications: Notification[], type: 'customer' | 'business' }) {
  const navigate = useNavigate()
  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ)

  function onNotificationClick(id:string, path: string) {
    markNotificationAsRead({
      variables: { id },
      refetchQueries: [{ query: (type === 'customer' ? GET_CURRENT_CUSTOMER : GET_CURRENT_BUSINESS_USER) }]
    })
    navigate(`${path}?ref=notifications`)
  }

  if (notifications.length) {
    return notifications.map(({ id, subject, path, isRead, createdAt, notifiableType, metadata}) => (
      <React.Fragment key={id}>
        <Grid xs={12} md={8} lg={5}>
          <Box
            onClick={() => onNotificationClick(id, path)}
            sx={{
              '&:hover': { cursor: 'pointer' },
              position: 'relative',
              backgroundColor: 'white',
              padding: '12px 16px',
              borderRadius: '12px',
              display: 'flex',
              gap: 2,
              mb: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {notifiableType === 'Estimate' && <BusinessLogo logoUrl={metadata} />}
              {notifiableType === 'Job' &&
                <Box sx={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: colors.primaryMuted, display: 'flex', color: colors.primary, justifyContent: 'center', alignItems: 'center' }}>
                  {renderIcon(metadata!)}
                </Box>}
              {notifiableType === 'Review' &&
                <Box sx={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: colors.background, display: 'flex', color: colors.gray3, justifyContent: 'center', alignItems: 'center' }}>
                  <RateReviewIcon />
                </Box>}
            </Box>
            <Box sx={{ width: '70%' }}>
              <Typography sx={{ fontWeight: isRead ? 400 : 600, color: isRead ? colors.gray2: colors.fontColor }}>{subject}</Typography>
              <Typography sx={{ color: colors.gray2, fontSize: fontSizes.sm }}>{dayjs(createdAt).fromNow()}</Typography>
            </Box>
            <Box sx={{ position: 'absolute', right: '25px', top: 'calc(50% - 12px)' }}>
              {!isRead && <CircleIcon sx={{ fontSize: '12px', color: colors.primary }}/>}
            </Box>
          </Box>
        </Grid>
        <Grid xs={0} lg={7} />
      </React.Fragment>
    ))
  } else {
    return (
      <Grid xs={12} md={9} lg={6}>
        <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ textAlign: 'center' }}>
            <NotificationsIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
            <p>You don&apos;t have any notifications yet</p>
          </Box>
        </Box>
      </Grid>
    )
  }
}
