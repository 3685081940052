import { Box, Typography } from '@mui/joy'
import { BusinessReviewType } from '../types/review-type'
import { colors, fontSizes } from '../layouts/Theme'
import RateReviewIcon from '@mui/icons-material/RateReview'
import { Rating } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export function BusinessReview({ businessReview: { customer, rating, job, description, createdAt } }: { businessReview: BusinessReviewType }) {
  return (
    <Box
      sx={{
        p: '20px',
        mt: 2,
        backgroundColor: '#FFF',
        borderRadius: '10px',
        color: colors.gray3,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '30%' }, mb: { xs: 1 } }}>
        <Typography level="title-md">{customer.fullName}</Typography>
        <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2 }}>Joined {dayjs(customer.createdAt).fromNow()}</Box>
      </Box>
      <Box sx={{ width: { xs: '100%', md: '70%' }, mb: { xs: 1 } }}>
        <Box sx={{ fontSize: fontSizes.regular, color: colors.gray3 }}>{job.summary}</Box>
        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <Rating readOnly value={rating} size="small" />
          <Typography level="body-sm">{dayjs(createdAt).fromNow()}</Typography>
        </Box>
        <Box sx={{ fontSize: fontSizes.regular, fontWeight: 300, color: colors.gray3, whiteSpace: 'pre-wrap' }}>{description}</Box>
      </Box>
    </Box>
  )
}

export default function BusinessReviews({ businessReviews }: { businessReviews: BusinessReviewType[] }) {
  const averageRating = businessReviews.reduce((acc, review) => acc + review.rating, 0) / businessReviews.length

  return (
    <>
      <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex' }}>
        {businessReviews.length ?
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography level="title-sm">Total Reviews</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                  <Typography level="title-lg">{businessReviews.length}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography level="title-sm">Average Rating</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                  <Typography level="title-lg">{averageRating.toFixed(1)}</Typography>
                  <Rating readOnly value={averageRating} precision={0.5} size="small" />
                </Box>
              </Box>
            </Box>
          </Box> :
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', gap: 1 }}>
            <Box sx={{ textAlign: 'center' }}>
              <RateReviewIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
              <p>You don&apos;t have reviews yet</p>
            </Box>
          </Box>}
      </Box>
      {businessReviews.map((businessReview) => {
        return (
          <BusinessReview key={businessReview.id} businessReview={businessReview} />
        )
      })}
    </>
  )
}
