//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box, IconButton } from '@mui/joy'
import CancelIcon from '@mui/icons-material/Cancel'
import { colors } from '../layouts/Theme'

export default function ImageUploadPreview({ imagePreviews, onRemove }: { imagePreviews: string[], onRemove: (index: number) => void }) {
  return (
    !!imagePreviews.length &&
      <Box sx={{ display: 'flex', mt: 4, gap: 2 }}>
        {imagePreviews.map((previewURL, index) => {
          return (
            <Box key={index} sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => onRemove(index)}
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  minHeight: '20px',
                  height: '20px',
                  minWidth: '20px',
                  width: '20px',
                  zIndex: 10,
                  backgroundColor: 'white',
                  borderRadius: '50%'
                }}
              >
                <CancelIcon fontSize="small"/>
              </IconButton>
              <img src={previewURL} width="100px" height="100px" style={{ objectFit: 'cover', borderRadius: '10px', border: `1px solid ${colors.gray3}` }} />
            </Box>
          )
        })}
      </Box>
  )
}
