//@ts-expect-error: This is needed by Rails
import React, { useContext } from "react"
import { Box, IconButton } from '@mui/joy'
import MenuIcon from '@mui/icons-material/Menu'
import { toggleSidebar } from '../utils/nav-utils'
import NotificationBadge from "../components/NotificationBadge"
import { BusinessUserContext } from "./business/BusinessUserContextProvider"
import { CustomerContext } from "./customer/CustomerContextProvider"
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { LayoutContext } from "./LayoutContextProvider"

const noHeaderPaths = [
  '/customer/notifications',
  '/business/notifications',
  '/business/coverage',
]

export default function Header() {
  const businessUser = useContext(BusinessUserContext)
  const customer = useContext(CustomerContext)
  const notificationCount = businessUser?.unreadNotificationsCount || customer?.unreadNotificationsCount
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { mobileMode } = useContext(LayoutContext)

  const hideHeader = mobileMode && (noHeaderPaths.includes(location.pathname) || searchParams.get('ref') === 'notifications')

  return (
    <Box
      sx={{
        display: { xs: hideHeader ? 'none' : 'flex', sm: 'none' },
        borderBottom: '1px solid #CCC',
        position: 'sticky',
        zIndex: 99,
        backgroundColor: 'white',
        top: 0,
        pb: '15px',
        pt: '10px',
        px: '20px',
        mb: '5px',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <IconButton
        variant="outlined"
        color="neutral"
        size="sm"
        sx={{ px: '7px', mr: '15px', mt: '5px' }}
        onClick={() => toggleSidebar()}
      >
        <MenuIcon />
      </IconButton>
      <img src="/logo.png" style={{ width: '130px', marginLeft: '-20px' }} />
      <Box sx={{ display: 'flex', visibility: mobileMode ? 'hidden' : 'visible', '&:hover': { cursor: 'pointer' }}} onClick={() => navigate(Object.keys(businessUser).length ? '/business/notifications' : '/customer/notifications')}>
        <NotificationsIcon sx={{ fontSize: '30px', mt: '5px' }}/>
        {!!notificationCount &&
          <Box sx={{ position: 'absolute', top: '20px', right: '13px' }}>
            <NotificationBadge count={notificationCount} />
          </Box>}
      </Box>
    </Box>
  )
}
