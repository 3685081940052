//@ts-expect-error: This is needed by Rails
import React, { Fragment } from 'react'
import Typography from '@mui/joy/Typography'
import JobType from '../../types/job-type'
import { useSuspenseQuery } from '@apollo/client'
import Grid from '@mui/joy/Grid'
import LeadCard from '../../components/LeadCard'
import { Box } from '@mui/joy'
import DeleteIcon from '@mui/icons-material/Delete'
import { colors } from '../../layouts/Theme'
import { GET_DISCARDED_LEADS } from '../../graphql/lead-queries'

export default function DiscardedLeadsPage() {
  const { data: { discardedLeads } } = useSuspenseQuery(GET_DISCARDED_LEADS)

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Discarded Leads</Typography>
      </Grid>
        {discardedLeads.length ?
          discardedLeads.map((job: JobType) => {
            return (
              <Fragment key={job.id}>
                <Grid xs={12} md={9} lg={6}>
                  <LeadCard job={job} discarded />
                </Grid>
                <Grid lg={6} />
              </Fragment>
          )}) :
          <Grid xs={12} md={9} lg={6}>
            <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ textAlign: 'center' }}>
                <DeleteIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
                <p>You don&apos;t have discarded leads</p>
              </Box>
            </Box>
          </Grid>
        }
    </Grid>
  )
}
