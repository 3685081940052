import { Box, FormControl, FormLabel, Input, Select, Option } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { JobAttributes, ModularCabinetJobAttributes } from '../../types/job-type'

export default function ModularCabinetForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: ModularCabinetJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Type of Cabinet</FormLabel>
        <Controller
          name="categoryAttrsValues.cabinetType"
          defaultValue={attrs?.cabinetType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What cabinet do you want?"
            >
              <Option value="Kitchen">Kitchen</Option>
              <Option value="TV Console">TV Console</Option>
              <Option value="Bedroom">Bedroom</Option>
              <Option value="Walk-in Closet">Walk-in Closet</Option>
              <Option value="Bathroom">Bathroom</Option>
              <Option value="Wine">Wine</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Estimated Dimension (L, W, H)</FormLabel>
        <Input {...register('categoryAttrsValues.estimatedDimension')} defaultValue={attrs?.estimatedDimension} required type="text" placeholder="E.g. 5m x 1m x 2m" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Material Preference</FormLabel>
        <Controller
          name="categoryAttrsValues.material"
          defaultValue={attrs?.material}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What material do you want to use?"
            >
              <Option value="Solid Wood">Solid Wood</Option>
              <Option value="Laminate">Laminate</Option>
              <Option value="Plywood">Plywood</Option>
              <Option value="Aluminium">Aluminium</Option>
              <Option value="Stainless Steel">Stainless Steel</Option>
              <Option value="Glass">Glass</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
