//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Grid, Typography } from '@mui/joy'
import { GET_BUSINESS_NOTIFICATIONS } from '../../graphql/notification-queries'
import { useSuspenseQuery } from '@apollo/client'
import NotificationsList from '../../components/NotificationsList'

export default function BusinessNotificationsPage() {
  const { data } = useSuspenseQuery(GET_BUSINESS_NOTIFICATIONS)

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>
          Notifications
        </Typography>
      </Grid>
      <NotificationsList notifications={data.businessUserNotifications} type="business" />
    </Grid>
  )
}
