import { gql, TypedDocumentNode } from '@apollo/client'
import ReviewType, { BusinessReviewType, CustomerReviewType } from '../types/review-type'

export const GET_CURRENT_BUSINESS_REVIEW: TypedDocumentNode<{ currentBusinessReview: BusinessReviewType }, { id: string }> = gql`
  query GetCurrentBusinessReview($id: ID!) {
    currentBusinessReview(id: $id) {
      id
      rating
      description
      createdAt
      job {
        id
        summary
      }
      customer {
        id
        fullName
        createdAt
      }
    }
  }
`

export const GET_BUSINESS_REVIEWS: TypedDocumentNode<{ businessReviews: BusinessReviewType[] }, { businessUserId: string, jobId: string }> = gql`
  query GetBusinessReviews($businessUserId: ID!, $jobId: ID!) {
    businessReviews(businessUserId: $businessUserId, jobId: $jobId) {
      id
      rating
      description
      createdAt
      job {
        id
        summary
      }
      customer {
        id
        fullName
        createdAt
      }
    }
  }
`

export const CREATE_REVIEW_AS_CLIENT: TypedDocumentNode<{ createReviewAsClient: { errors: string[], review: ReviewType } }, { ownerId: string, jobId: string, rating: number, description?: string }> = gql`
  mutation CreateReviewAsClient($ownerId: String!, $jobId: String!, $rating: Int!, $description: String) {
    createReviewAsClient(input: { ownerId: $ownerId, jobId: $jobId, rating: $rating, description: $description }) {
      errors
      review {
        id
        rating
        description
        ownerId
        ownerType
        createdAt
      }
    }
  }
`

export const CURRENT_BUSINESS_REVIEWS: TypedDocumentNode<{ currentBusinessReviews: BusinessReviewType[] }> = gql`
  query GetCurrentBusinessReviews {
    currentBusinessReviews {
      id
      rating
      description
      createdAt
      job {
        id
        summary
      }
      customer {
        id
        fullName
        createdAt
      }
    }
  }
`

export const CURRENT_CUSTOMER_REVIEWS: TypedDocumentNode<{ currentCustomerReviews: CustomerReviewType[] }> = gql`
  query GetCurrentBusinessReviews {
    currentBusinessReviews {
      id
      rating
      description
      createdAt
      job {
        id
        summary
      }
      customer {
        id
        fullName
        createdAt
      }
    }
  }
`
