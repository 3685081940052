import { Box, Grid, Typography } from '@mui/joy'
import BusinessLoginOptions from '../../components/BusinessLoginOptions'

export default function BusinessLoginPage() {
  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={4}>
        <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <BusinessLoginOptions />
        </Box>
      </Grid>
    </Grid>
  )
}
