//@ts-expect-error: This is needed by Rails
import React, { Fragment, useContext } from 'react'
import Typography from '@mui/joy/Typography'
import { GET_LEADS } from '../../graphql/job-queries'
import JobType from '../../types/job-type'
import { useSuspenseQuery } from '@apollo/client'
import Grid from '@mui/joy/Grid'
import LeadCard from '../../components/LeadCard'
import { useNavigate } from 'react-router-dom'
import { BusinessUserContext } from '../../layouts/business/BusinessUserContextProvider'
import { Box, Button } from '@mui/joy'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import { colors } from '../../layouts/Theme'

export default function LeadsPage() {
  const navigate = useNavigate()
  const { data: { leads } } = useSuspenseQuery(GET_LEADS)
  const businessUser = useContext(BusinessUserContext)

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Leads</Typography>
      </Grid>
      {businessUser.businessProfile?.coverageCompleted ?
        <>
          {leads.length ?
            leads.map((job: JobType) => {
              return (
                <Fragment key={job.id}>
                  <Grid xs={12} md={9} lg={6}>
                    <LeadCard job={job} />
                  </Grid>
                  <Grid lg={6} />
                </Fragment>
            )}) :
            <Grid xs={12} md={9} lg={6}>
              <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ textAlign: 'center' }}>
                  <PersonSearchIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
                  <p>Wala pang leads at the moment. Makakatanggap ka ng email sa {businessUser.email} once mayroon ng pumasok na lead.</p>
                </Box>
              </Box>
            </Grid>
          }
        </> :
        <Grid xs={12} md={9} lg={6}>
          <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <PersonSearchIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
              <p>Please complete your coverage to see leads</p>
              <Button variant="outlined" color="neutral" onClick={() => navigate('/business/coverage')}>Complete Coverage</Button>
            </Box>
          </Box>
        </Grid>}
    </Grid>
  )
}
