import { Box, FormControl, FormLabel, Input, Select, Option } from "@mui/joy";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { JobAttributes, RenovationJobAttributes } from "../../types/job-type";

export default function RenovationForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: RenovationJobAttributes
}) {
  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Property Area for Renovation</FormLabel>
        <Controller
          name="categoryAttrsValues.propertyArea"
          defaultValue={attrs?.propertyArea}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Property area that will be renovated"
            >
              <Option value="Kitchen">Kitchen</Option>
              <Option value="Living Room">Living Room</Option>
              <Option value="Bedroom">Bedroom</Option>
              <Option value="Bathroom">Bathroom</Option>
              <Option value="Garage">Garage</Option>
              <Option value="Flooring">Flooring</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Floor area (sqm)</FormLabel>
        <Input {...register('categoryAttrsValues.floorArea')} defaultValue={attrs?.floorArea} required type="number" placeholder="Estimated floor area" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Existing Condition</FormLabel>
        <Controller
          name="categoryAttrsValues.existingCondition"
          defaultValue={attrs?.existingCondition}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Select condition of the area"
            >
              <Option value="Raw Space">Raw Space</Option>
              <Option value="Bare">Bare</Option>
              <Option value="Semi-Finished">Semi-Finished</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Type of Finish</FormLabel>
        <Controller
          name="categoryAttrsValues.typeOfFinish"
          defaultValue={attrs?.typeOfFinish}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Your desired finish"
            >
              <Option value="Basic">Basic</Option>
              <Option value="Standard">Standard</Option>
              <Option value="Premium">Premium</Option>
              <Option value="Luxury">Luxury</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
