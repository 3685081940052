//@ts-expect-error: This is needed by Rails
import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import JobCategoryType from '../../types/job-category-type'
import CityType from '../../types/city-type'
import { useNavigate } from 'react-router-dom'
import { CustomerContext } from '../../layouts/customer/CustomerContextProvider'
import LocationAutocomplete from '../../components/LocationAutocomplete'
import JobCategoryAutocomplete from '../../components/JobCategoryAutocomplete'
import { Box, DialogContent, Modal, ModalDialog } from '@mui/joy'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import JobCategories from '../../components/JobCategories'
import { useMutation } from '@apollo/client'
import { CREATE_JOB, GET_JOBS } from '../../graphql/job-queries'
import { GET_CURRENT_CUSTOMER } from '../../graphql/user-queries'
import BarLoader from 'react-spinners/BarLoader'
import { colors } from '../../layouts/Theme'
import { extractJobFromSignUp } from '../../utils/general-utils'

export default function GetEstimatesPage() {
  const navigate = useNavigate()
  const currentCustomer = useContext(CustomerContext)

  const [city, setCity] = useState<CityType | null>(currentCustomer?.lastCity || null)
  const [jobCategory, setJobCategory] = useState<JobCategoryType | null>(null)
  const { showAlert } = useContext(LayoutContext)

  const [createJob, { loading: creatingJob }] = useMutation(CREATE_JOB, {
    refetchQueries: [GET_CURRENT_CUSTOMER, { query: GET_JOBS }]
  })

  useEffect(() => {
    maybeSubmitNewJobFromLandingPage()
    // TODO: Maybe save temporary job
  }, [])

  useEffect(() => {
    if (currentCustomer) {
      setCity(currentCustomer.lastCity)
    }
  }, [currentCustomer])

  function maybeSubmitNewJobFromLandingPage() {
    const jobAttributes = extractJobFromSignUp()

    if (jobAttributes) {
      createJob({
        variables: { attributes: jobAttributes },
        onCompleted: () => {
          navigate('/customer/jobs')
          showAlert('Your job has been posted')
        },
      })
    }
  }

  function navigateToNewJobPage(jobCategorySlug = jobCategory?.slug) {
    if (jobCategorySlug && city) {
      navigate(`/customer/new_job/${jobCategorySlug}/location/${city.id}`)
    } else {
      showAlert('Please select location and category to get started', 'danger')
    }
  }

  function handleJobCategoryClick(jobCategory: JobCategoryType | null) {
    if (city) {
      navigateToNewJobPage(jobCategory?.slug)
    } else {
      setJobCategory(jobCategory)
    }
  }

  return (
    <Grid container spacing={1}>
      <Modal open={creatingJob}>
        <ModalDialog>
          <DialogContent sx={{ textAlign: 'center' }}>
            <Typography sx={{ mb: 1 }} level="body-sm">Submitting your job</Typography>
            <BarLoader color={colors.primary} width="100%" />
          </DialogContent>
        </ModalDialog>
      </Modal>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 1 }}>
          Get Estimates
        </Typography>
      </Grid>
      <Grid xs={12} lg={9}>
        <Box sx={{ p: '20px 20px 30px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <FormControl>
                <FormLabel>Location</FormLabel>
                <LocationAutocomplete
                  onValueChange={(value) => setCity(value as CityType)}
                  value={city}
                  autoFocus={!city?.id ? true : undefined}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={4}>
              <FormControl>
                <FormLabel>Category</FormLabel>
                <JobCategoryAutocomplete
                  onValueChange={(value) => setJobCategory(value as JobCategoryType)}
                  value={jobCategory}
                  autoFocus={city?.id && !jobCategory?.id ? true : undefined}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={4}>
              <Box sx={{ display: 'flex', alignItems: 'end', height: '100%', mt: { xs: '5px', md: '0' } }}>
                <Button onClick={() => navigateToNewJobPage()}>
                  Get Started
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <JobCategories selectedJobCategory={jobCategory} onClick={handleJobCategoryClick}/>
    </Grid>
  )
}
