import { Box, FormControl, FormLabel, Select, Option } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { CleaningJobAttributes, JobAttributes } from '../../types/job-type'

export default function CleaningForm({ control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: CleaningJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Cleaning Type</FormLabel>
        <Controller
          name="categoryAttrsValues.cleaningType"
          defaultValue={attrs?.cleaningType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What kind of cleaning do you need?"
            >
              <Option value="Deep Cleaning">Deep Cleaning</Option>
              <Option value="Regular Cleaning">Regular Cleaning</Option>
              <Option value="Move-In/Move-Out Cleaning">Move-In/Move-Out Cleaning</Option>
              <Option value="Post-Construction Cleaning">Post-Construction Cleaning</Option>
              <Option value="Carpet and Upholstery Cleaning">Carpet and Upholstery Cleaning</Option>
              <Option value="Window Cleaning">Window Cleaning</Option>
              <Option value="One-Time Cleaning">One-Time Cleaning</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Focus Area</FormLabel>
        <Controller
          name="categoryAttrsValues.focusArea"
          defaultValue={attrs?.focusArea}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Focus area for cleaning"
            >
              <Option value="Kitchen">Kitchen</Option>
              <Option value="Bathrooms">Bathrooms</Option>
              <Option value="Bedrooms">Bedrooms</Option>
              <Option value="Living Room">Living Room</Option>
              <Option value="Floors and Carpets">Floors and Carpets</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Size</FormLabel>
        <Controller
          name="categoryAttrsValues.areaSize"
          defaultValue={attrs?.areaSize}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="How many bedrooms do you have?"
            >
              <Option value="Studio Apartment">Studio Apartment</Option>
              <Option value="1-Bedroom Apartment">1-Bedroom Apartment</Option>
              <Option value="2-Bedroom House">2-Bedroom House</Option>
              <Option value="3-Bedroom House">3-Bedroom House</Option>
              <Option value="4+ Bedroom House">4+ Bedroom House</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Condition</FormLabel>
        <Controller
          name="categoryAttrsValues.condition"
          defaultValue={attrs?.condition}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Current condition of the place?"
            >
              <Option value="Well-maintained">Well-maintained</Option>
              <Option value="Slightly cluttered">Slightly cluttered</Option>
              <Option value="Very dirty">Very dirty</Option>
              <Option value="Post-renovation">Post-renovation</Option>
              <Option value="Move-in ready">Move-in ready</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
