import { Box, Typography } from '@mui/joy'
import { ModularCabinetJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function ModularCabinetDetails({ attrs }: { attrs: ModularCabinetJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Type of Cabinet</Typography>
        <Typography level="title-sm">{attrs.cabinetType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Estimated Dimension (L, W, H)</Typography>
        <Typography level="title-sm">{attrs.estimatedDimension}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Material Preference</Typography>
        <Typography level="title-sm">{attrs.material}</Typography>
      </Box>
    </Box>
  )
}
