import { isEmpty } from "lodash"
import { CategoryAttributesUnion, ConstructionJobAttributes } from "../types/job-type"

export function allCategoryAttributesHaveValues(categorySlug: string, attrs: CategoryAttributesUnion | undefined) {
  if (!attrs) { return true }

  switch(categorySlug) {
    case 'construction':
      const constructionAttrs = attrs as ConstructionJobAttributes
      return !isEmpty(constructionAttrs.lotArea)
              && !isEmpty(constructionAttrs.floorArea)
              && !isEmpty(constructionAttrs.numberOfFloors)
              && !isEmpty(constructionAttrs.typeOfFinish)
              && !isEmpty(constructionAttrs.hasBlueprint)
    default:
      return true
  }
}
