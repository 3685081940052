import { TypedDocumentNode, gql } from "@apollo/client";
import DiscardedLead from "../types/discarded-lead-type";
import JobType from "../types/job-type";

export const CREATE_DISCARDED_LEAD: TypedDocumentNode<{ createDiscardedLead: { errors: string[], discardedLead: DiscardedLead } }, { jobId: string }> = gql`
  mutation CreateDiscardedLead($jobId: ID!) {
    createDiscardedLead(input: { jobId: $jobId }) {
      errors
      discardedLead {
        id
        jobId
        businessUserId
      }
    }
  }
`

export const GET_DISCARDED_LEADS: TypedDocumentNode<{ discardedLeads: JobType[] }> = gql`
  query GetDiscardedLeads {
    discardedLeads {
      id
      uuid
      jobCategory {
        id
        name
        slug
        price
      }
      city {
        id
        name
      }
      summary
      images {
        id
        url
        thumbnail
        width
        height
      }
      budgetMin
      budgetMax
      timeline
      description
      createdAt
      updatedAt
    }
  }
`
