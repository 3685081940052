//@ts-expect-error: This is needed by Rails
import React, { useEffect } from 'react'
import ImageType from '../types/image-type';
import { Box } from '@mui/joy';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

export default function ImagesPreview({ images, thumbnailSize = 50 }: { images: ImageType[], thumbnailSize?: number }) {
  if (!images.length) { return }

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#jobImages',
      children: 'a',
      pswpModule: () => import('photoswipe')
    })
    lightbox.init()

    return () => {
      lightbox.destroy()
    }
  }, [])

  return (
    <Box id="jobImages" sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {images.map(({ id, thumbnail, url, width, height }) => (
        <a href={url} key={id} data-pswp-width={width} data-pswp-height={height} target="_blank" rel="noreferrer">
          <img
            key={id}
            src={thumbnail}
            style={{
              width: `${thumbnailSize}px`,
              height: `${thumbnailSize}px`,
              objectFit: 'cover',
              borderRadius: '8px'
            }}
          />
        </a>
      ))}
    </Box>
  )
}
