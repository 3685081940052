//@ts-expect-error: This is needed by Rails
import React from 'react'
import Typography from '@mui/joy/Typography'
import { useSuspenseQuery } from '@apollo/client'
import { GET_ESTIMATES } from '../../graphql/estimate-queries'
import { Box, Button, Grid } from '@mui/joy'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import { colors, fontSizes } from '../../layouts/Theme'
import CategoryIcon from '../../components/CategoryIcon'
import DisplayMinMax from '../../components/DisplayMinMax'
import DisplayAvailability from '../../components/DisplayAvailability'
import { useNavigate } from 'react-router-dom'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'

export default function EstimatesPage() {
  const navigate = useNavigate()
  const { data: { estimates } } = useSuspenseQuery(GET_ESTIMATES)
  dayjs.extend(relativeTime)

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Estimates</Typography>
      </Grid>
      {estimates.length ?
        estimates.map(({ id, createdAt, estimateMin, estimateMax, availability, job: { uuid, jobCategory, summary }}) => (
          <React.Fragment key={id}>
            <Grid xs={12} md={9} lg={6}>
              <Box sx={{ backgroundColor: '#FFF', padding: '20px', mb: 2, borderRadius: '20px' }}>
                <Box sx={{ display: 'flex' }}>
                  <CategoryIcon categorySlug={jobCategory.slug} />
                  <Box>
                    <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600, color: colors.fontColor }}>Estimate for {summary}</Box>
                    <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2 }}>
                      Sent {dayjs(createdAt).fromNow()}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', mt: 2, gap: 1, flexWrap: 'wrap' }}>
                  <DisplayMinMax valueMin={estimateMin} valueMax={estimateMax} />
                  <DisplayAvailability availability={availability} /> 
                </Box>
                <Button variant="outlined" color="neutral" sx={{ mt: 2 }} onClick={() => navigate(`/business/leads/${uuid}`)}>View Estimate</Button>
              </Box>
            </Grid>
            <Grid lg={6} />
          </React.Fragment>
        )) :
        <Grid xs={12} md={9} lg={6}>
          <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <RequestQuoteIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
              <p>You haven&apos;t sent any estimates yet</p>
              <Button variant="outlined" color="neutral" onClick={() => navigate('/business/leads')}>View Leads</Button>
            </Box>
          </Box>
        </Grid>}
    </Grid>
  )
}
