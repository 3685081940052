//@ts-expect-error: This is needed by Rails
import React, { useContext, useState } from 'react'
import { DialogTitle, Button, Modal, ModalDialog, Box, FormLabel, FormControl, Textarea } from "@mui/joy"
import { fontSizes } from '../layouts/Theme'
import BusinessUserType from '../types/business-user-type'
import { Rating } from '@mui/material'
import { useMutation } from '@apollo/client'
import { CREATE_REVIEW_AS_CLIENT } from '../graphql/review-queries'
import { LayoutContext } from '../layouts/LayoutContextProvider'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  businessUser: BusinessUserType
  jobId: string
}

export default function AddBusinessReviewModal({ open, setOpen, businessUser, jobId }: Props) {
  const [rating, setRating] = useState(5)
  const [description, setDescription] = useState('')
  const { showAlert } = useContext(LayoutContext)

  const [submitReview, { loading: saving }] = useMutation(CREATE_REVIEW_AS_CLIENT, {
    variables: {
      jobId: jobId,
      ownerId: businessUser.id,
      rating,
      description
    },
    onCompleted: ({ createReviewAsClient: { errors }}) => {
      if (errors.length) {
        showAlert(errors.join('. '), 'danger')
      } else {
        setOpen(false)
        showAlert('Your review has been submitted!', 'success')
      }
    }
  })

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog sx={{ width: { xs: '80%', md: '50%' }}}>
        <DialogTitle sx={{ fontSize: fontSizes.large }}>
          Write a Review for {businessUser.businessProfile.name}
        </DialogTitle>
        <FormControl sx={{ mb: 1 }}>
          <FormLabel>Choose Rating</FormLabel>
          <Rating value={rating} onChange={(_, val) => setRating(val || 0)} />
        </FormControl>
        <FormControl>
          <FormLabel>Care to share more details?</FormLabel>
          <Textarea
            autoFocus
            minRows={5}
            value={description}
            onChange={({ target: { value }}) => setDescription(value)}
            placeholder="Give more details on why you're giving this rating to this business"
          />
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Button onClick={() => submitReview()} disabled={saving}>
            Submit Review
          </Button>
          <Button color="neutral" variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
        </Box>
      </ModalDialog>
    </Modal>
  )
}
