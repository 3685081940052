//@ts-expect-error: This is needed by Rails
import React, { ChangeEvent, useContext, useState } from 'react'
import Typography from '@mui/joy/Typography'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_BUSINESS_PROFILE, UPDATE_BUSINESS_PROFILE } from '../../graphql/business-profile-queries'
import { Box, Button, Divider, FormControl, FormLabel, Grid, Input, Textarea } from '@mui/joy'
import { UpdateBusinessProfileAttributes } from '../../types/business-profile-type'
import { useForm } from 'react-hook-form'
import { GET_LEADS } from '../../graphql/job-queries'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import AddProjectModal from '../../components/AddProjectModal'
import { GET_PROJECTS } from '../../graphql/project-queries'
import { colors } from '../../layouts/Theme'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ProjectCard from '../../components/ProjectCard'

export default function ProfilePage() {
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [updateBusinessProfile, { loading }] = useMutation(UPDATE_BUSINESS_PROFILE)
  const { data: { projects } } = useSuspenseQuery(GET_PROJECTS)
  const { showAlert } = useContext(LayoutContext)
  const {
    data: {
      businessProfile: {
        completed,
        name,
        yearEstablished,
        summary,
        address,
        contactNumber,
        email,
        socialMediaLink,
        logoUrl,
      }
    }
  } = useSuspenseQuery(GET_BUSINESS_PROFILE)

  const [logoPreview, setLogoPreview] = useState(logoUrl)

  const { register, handleSubmit, setValue } = useForm<UpdateBusinessProfileAttributes>({
    defaultValues: {
      name,
      yearEstablished,
      summary,
      address,
      contactNumber,
      email,
      socialMediaLink,
    }
  })

  function onSubmit(attributes: UpdateBusinessProfileAttributes) {
    updateBusinessProfile({
      variables: { attributes },
      refetchQueries: [{ query: GET_LEADS }],
      onCompleted: () => {
        showAlert('Your profile has been updated')
      }
    })
  }

  function handleLogoChange(e: ChangeEvent<HTMLInputElement>) {
    // TODO: Move this in a centralized place
    const MAX_FILE_SIZE = 5 * 1024 * 1024
    const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/gif']
    const errors: string[] = []

    const selectedFile = e.target.files?.[0]

    if (!selectedFile) {
      return
    } else if (selectedFile.size > MAX_FILE_SIZE) {
      errors.push(`${selectedFile.name} exceeds the maximum size of 5MB`)
    } else if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      errors.push(`${selectedFile.name} is not a supported image type. Supported image types are: .jpeg, .png, and .gif`)
    }

    if (errors.length === 0) {
      setValue('logo', selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
    } else {
      showAlert(errors.join("\n"), 'danger')
    }
  }

  return (
    <Grid container sx={{ mb: '20px' }}>
      <Grid xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography level="h1" sx={{ mb: 2 }}>{completed ? 'Profile' : 'Complete Your Profile'}</Typography>
      </Grid>
      <Grid xs={12} lg={9}>
        <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid xs={12} md={6}>
              <Typography sx={{ mb: '10px' }} level="h3">Your Business Info</Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <Button variant="plain" component="label" sx={{ pl: 0, bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '75px', height: '75px', backgroundColor: logoPreview ? colors.white : colors.primaryMuted, color: colors.primary, borderRadius: '50%' }}>
                      {logoPreview && <img src={logoPreview} style={{ objectFit: 'cover', width: '75px', height: '75px', borderRadius: '50%' }} />}
                      {!logoPreview && <EngineeringIcon />}
                    </Box>
                    <input
                      style={{
                        height: '1px',
                        width: '1px',
                        clip: 'rect(0 0 0 0)',
                        clipPath: 'inset(50%)',
                        overflow: 'hidden',
                        position: 'absolute',
                        bottom: 0,
                        left: 0
                      }}
                      type="file"
                      onChange={handleLogoChange}
                    />
                    <Typography level="body-sm" sx={{ display: 'block', mt: 1, color: colors.fontColor }}>Upload Logo</Typography>
                  </Box>
                </Button>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel required>Business Name</FormLabel>
                  <Input {...register("name")} placeholder="Business name" required />
                </FormControl>
                <FormControl sx={{ mt: '15px' }}>
                  <FormLabel required>Year Established</FormLabel>
                  <Input {...register("yearEstablished")} placeholder="Year Established" required />
                </FormControl>
                <FormControl sx={{ mt: '15px' }}>
                  <FormLabel required>Summary</FormLabel>
                  <Textarea {...register("summary")} minRows={5} required placeholder="Provide a short summary of your business offerings" />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel required>Address</FormLabel>
                  <Input {...register("address")} required placeholder="Address" />
                </FormControl>
                <FormControl sx={{ mt: '15px' }}>
                  <FormLabel required>Contact number</FormLabel>
                  <Input {...register("contactNumber")} required placeholder="Contact number" />
                </FormControl>
                <FormControl sx={{ mt: '20px' }}>
                  <FormLabel required>Email</FormLabel>
                  <Input {...register("email")} required placeholder="Email" />
                </FormControl>
                <FormControl sx={{ mt: '25px' }}>
                  <FormLabel required>Social Media Account</FormLabel>
                  <Input {...register("socialMediaLink")} required placeholder="Social Media Page or Account" />
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ mt: '20px', mb: '10px' }} inset="context" />
            <>
              <Grid xs={12}>
                {openProjectModal && <AddProjectModal open={openProjectModal} setOpen={setOpenProjectModal} />}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography sx={{ mb: '10px' }} level="h3">Portfolio</Typography>
                  <Button color="neutral" variant="outlined" onClick={() => setOpenProjectModal(true)}>Add Portfolio</Button>
                </Box>
              </Grid>
              {!!projects.length &&
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {projects.map((project) => (
                    <Grid xs={12} md={6} key={project.id}>
                      <ProjectCard project={project} />
                    </Grid>
                  ))}
                </Grid>}
              {projects.length === 0 && <Typography level="body-sm">You have not added any portfolios yet</Typography>}
            </>
            <Grid xs={12} >
              <Button sx={{ mt: 4 }} type="submit" disabled={loading}>{loading ? 'Saving': 'Save Profile'}</Button>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  )
}
