import { Box, Typography } from '@mui/joy'
import { ElectricalJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function ElectricalDetails({ attrs }: { attrs: ElectricalJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Electrical Service Needed</Typography>
        <Typography level="title-sm">{attrs.electricalService}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Scope</Typography>
        <Typography level="title-sm">{attrs.scope}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Issue</Typography>
        <Typography level="title-sm">{attrs.issue}</Typography>
      </Box>
    </Box>
  )
}
