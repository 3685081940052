import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'
import { router } from './router'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { appTheme } from './layouts/Theme'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const materialTheme = materialExtendTheme()

const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
  link: createUploadLink(),
});

const reactAppElement = document.getElementById('react_app')

if (reactAppElement) {
  ReactDOM.createRoot(reactAppElement).render(
    <React.StrictMode>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider theme={appTheme}>
          <CssBaseline />
          <ApolloProvider client={client}>
            <RouterProvider router={router} />
          </ApolloProvider>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </React.StrictMode>
  )
}
