import { Box, FormControl, FormLabel, Input, Select, Option } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { AirconJobAttributes, JobAttributes } from '../../types/job-type'

export default function AirconForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: AirconJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl>
        <FormLabel>Number of units</FormLabel>
        <Input {...register('categoryAttrsValues.numberOfUnits')} defaultValue={attrs?.numberOfUnits} required type="number" placeholder="Ilan ang aircon mo?" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Type of Service Needed</FormLabel>
        <Controller
          name="categoryAttrsValues.serviceType"
          defaultValue={attrs?.serviceType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What service do you need?"
            >
              <Option value="Cleaning">Cleaning</Option>
              <Option value="Diagnosis and Repair">Diagnosis and Repair</Option>
              <Option value="Installation">Installation</Option>
              <Option value="Freon Refill">Freon Refill</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Aircon Type</FormLabel>
        <Controller
          name="categoryAttrsValues.airconType"
          defaultValue={attrs?.airconType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Anong klase ang aircon mo?"
            >
              <Option value="Split Type">Split Type</Option>
              <Option value="Window Type">Window Type</Option>
              <Option value="Standing">Standing</Option>
              <Option value="Ceiling">Ceiling</Option>
              <Option value="Portable">Portable</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Brand</FormLabel>
        <Controller
          name="categoryAttrsValues.brand"
          defaultValue={attrs?.brand}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Anong brand ang aircon mo?"
            >
              <Option value="Carrier">Carrier</Option>
              <Option value="Columbia">Columbia</Option>
              <Option value="Daikin">Daikin</Option>
              <Option value="Fujidenzo">Fujidenzo</Option>
              <Option value="Fujitsu">Fujitsu</Option>
              <Option value="Hitachi">Hitachi</Option>
              <Option value="Koppel">Koppel</Option>
              <Option value="LG">LG</Option>
              <Option value="Mitsubishi">Mitsubishi</Option>
              <Option value="Panasonic">Panasonic</Option>
              <Option value="Samsung">Samsung</Option>
              <Option value="Sanyo">Sanyo</Option>
              <Option value="Sharp">Sharp</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
