//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box, Typography } from '@mui/joy'
import Project from '../types/project-type'

export default function ProjectCard({ project }: { project: Project }) {
  const { name, description, images } = project

  return (
    <Box sx={{ gap: 2, display: 'flex', flexDirection: 'row', height: '140px', bgcolor: 'var(--joy-palette-neutral-100)', borderRadius: '10px', p: '15px' }}>
      {!!images?.length &&
        <Box sx={{ 'img': { objectFit: 'cover', width: '120px', height: '140px', borderRadius: '10px 0 0 10px', ml: '-15px', mt: '-15px' }}}>
          <img src={images?.[0]?.url} />
        </Box>}
      <Box sx={{ overflow: 'hidden' }}>
        <Typography level="title-lg">{name}</Typography>
        <Typography level="body-sm">{description}</Typography>
      </Box>
    </Box>
  )
}
