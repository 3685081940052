import { gql, TypedDocumentNode } from '@apollo/client'
import EstimateType, { EstimateAttributes } from '../types/estimate-type'

export const GET_ESTIMATE_FOR_JOB: TypedDocumentNode<{ estimateForJob: EstimateType }, { uuid: string }> = gql`
  query GetEstimateForJob($uuid: ID!) {
    estimateForJob(uuid: $uuid) {
      id
      estimateMin
      estimateMax
      availability
      details
      createdAt
    }
  }
`

export const GET_ESTIMATES: TypedDocumentNode<{ estimates: EstimateType[] }> = gql`
  query GetEstimates {
    estimates {
      id
      estimateMin
      estimateMax
      availability
      details
      job {
        id
        uuid
        jobCategory { name, slug }
        city { name }
        summary
        customer {
          id
          fullName
        }
      }
      createdAt
    }
  }
`

export const GET_JOB_ESTIMATES: TypedDocumentNode<{ jobEstimates: EstimateType[] }, { jobId: string }> = gql`
  query GetJobEstimates($jobId: ID!) {
    jobEstimates(jobId: $jobId) {
      id
      jobId
      estimateMin
      estimateMax
      availability
      details
      createdAt
      businessUser {
        id
        businessProfile {
          id
          name
          yearEstablished
          contactNumber
          email
          summary
          address
          socialMediaLink
        }
      }
    }
  }
`

export const GET_JOB_ESTIMATE: TypedDocumentNode<{ jobEstimate: EstimateType }, { jobId: string, estimateId: string }> = gql`
  query GetJobEstimate($jobId: ID!, $estimateId: ID!) {
    jobEstimate(jobId: $jobId, estimateId: $estimateId) {
      id
      estimateMin
      estimateMax
      availability
      details
      createdAt
      job {
        id
        uuid
        jobCategory { name }
        city { name }
      }
      businessUser {
        id
        businessProfile {
          id
          name
          yearEstablished
          contactNumber
          email
          summary
          address
          socialMediaLink
          logoUrl
        }
      }
      businessProjects {
        id
        name
        description
        images {
          id
          url
          thumbnail
          width
          height
        }
      }
      businessReviews {
        id
        rating
        description
        createdAt
      }
    }
  }
`

export const UPDATE_ESTIMATE: TypedDocumentNode<{ errors: [string], estimate: EstimateType }, { id: string, attributes: Omit<EstimateAttributes, 'jobId'> }> = gql`
  mutation UpdateEstimate($id: ID!, $attributes: UpdateEstimateAttributes!) {
    updateEstimate(input: { id: $id, attributes: $attributes }) {
      errors
      estimate {
        job {
          id
        }
        estimateMin
        estimateMax
        availability
        details
      }
    }
  }
`

export const CREATE_ESTIMATE: TypedDocumentNode<{ createEstimate: { errors: [string], estimate: EstimateType } }, { attributes: EstimateAttributes }> = gql`
  mutation CreateEstimate($attributes: EstimateAttributes!) {
    createEstimate(input: { attributes: $attributes }) {
      errors
      estimate {
        job {
          id
        }
        estimateMin
        estimateMax
        availability
        details
      }
    }
  }
`
