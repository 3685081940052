import { Box, FormControl, FormLabel, Input, Select, Option } from '@mui/joy'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { JobAttributes, PestControlJobAttributes } from '../../types/job-type'

export default function PestControlForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: PestControlJobAttributes
}) {

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Type of Property</FormLabel>
        <Controller
          name="categoryAttrsValues.propertyType"
          defaultValue={attrs?.propertyType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="What's your property type?"
            >
              <Option value="House">House</Option>
              <Option value="Condominium">Condominium</Option>
              <Option value="Apartment">Apartment</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Floor area (sqm)</FormLabel>
        <Input {...register('categoryAttrsValues.floorArea')} defaultValue={attrs?.floorArea} required type="number" placeholder="Size of your property in sqm" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Pest Identification</FormLabel>
        <Controller
          name="categoryAttrsValues.pestType"
          defaultValue={attrs?.pestType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Anong klaseng pest ito?"
            >
              <Option value="Ants">Ants</Option>
              <Option value="Termites">Termites</Option>
              <Option value="Cockroaches">Cockroaches</Option>
              <Option value="Bed Bugs">Bed Bugs</Option>
              <Option value="Rodents (Mice and Rats)">Rodents (Mice and Rats)</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Severity</FormLabel>
        <Controller
          name="categoryAttrsValues.severity"
          defaultValue={attrs?.severity}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Gaano na ito kadami o kalala?"
            >
              <Option value="Severe Infestation">Severe Infestation</Option>
              <Option value="Moderate Infestation">Moderate Infestation</Option>
              <Option value="Minor Infestation">Minor Infestation</Option>
              <Option value="Emerging Infestation">Emerging Infestation</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
