//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box } from '@mui/joy'
import { colors } from '../layouts/Theme'
import { renderIcon } from '../utils/render-utils'

export default function CategoryIcon({ categorySlug }: { categorySlug: string }) {
  return (
    <Box
      sx={{
        mr: 1,
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        bgcolor: colors.primaryMuted,
        color: colors.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: '0',
      }}>
      {renderIcon(categorySlug, '20px')}
    </Box>
  )
}
