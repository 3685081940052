//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box } from '@mui/joy'
import { colors } from '../layouts/Theme'
import EngineeringIcon from '@mui/icons-material/Engineering'

export default function BusinessLogo({ logoUrl, size = '50px' }: { logoUrl: string | null, size?: string }) {
  return (
    logoUrl ?
      <img src={logoUrl} style={{ width: size, height: size, objectFit: 'cover', borderRadius: '50%' }} /> :
      <Box sx={{ color: colors.primary, bgcolor: colors.primaryMuted, width: size, height: size, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><EngineeringIcon /></Box>
  )
}
