//@ts-expect-error: This is needed by Rails
import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Chip, ChipDelete } from '@mui/joy'
import { useSuspenseQuery } from '@apollo/client'
import { GET_CITIES, GET_PROVINCES, Province } from '../graphql/location-queries'
import CityType from '../types/city-type'
import { fontSizes } from '../layouts/Theme'
import CloseIcon from '@mui/icons-material/Close'
import { groupBy, uniq, uniqBy } from 'lodash'
import Fuse from 'fuse.js'

export interface LocationOption {
  id: string
  locationId: string
  locationType: string
  name: string
  provinceId: string | null
}

export function convertCitiesToLocations(cities: CityType[]) {
  return cities.map(({ id, name, provinceName, provinceId }) => (
    { id, provinceId, name: `${name}, ${provinceName}`, locationType: 'city', locationId: `city#${id}` } as LocationOption
  ))
}

function convertProvincesToLocations(provinces: Province[]) {
  return provinces.map(({ id, name }) => ({
    id,
    provinceId: null,
    name: `${name}${name === 'Metro Manila' ? '' : ' Province'}`,
    locationType: 'province',
    locationId: `province#${id}`
  } as LocationOption))
}

function compactLocations(locations: LocationOption[], citiesRef: CityType[], provincesRef: Province[]) {
  const groupedLocationsSource = groupBy(citiesRef, 'provinceId')
  const groupedLocations = groupBy(locations, 'provinceId')
  const completedProvinceIds: string[] = []

  Object.entries(groupedLocations).forEach(([provinceId, locations]) => {
    if (groupedLocationsSource[provinceId]?.length === locations.length) {
      completedProvinceIds.push(provinceId)
    } 
  })
  const completedProvinces = provincesRef.filter(({id}) => completedProvinceIds.includes(id))
  let compactedLocations = convertProvincesToLocations(completedProvinces) 

  locations.forEach((location) => {
    if (!location.provinceId || (location.provinceId && !completedProvinceIds.includes(location.provinceId))) {
      compactedLocations = [...compactedLocations, location]
    }
  })

  return compactedLocations
}

interface Props {
  onCityIdsChange: (cityIds: string[]) => void
  onLocationsChange: (val: LocationOption[]) => void
  locations: LocationOption[]
  autoFocus?: boolean
}

export default function LocationCoverage({ onCityIdsChange, onLocationsChange, locations, autoFocus = false }: Props) {
  const { data: { provinces } } = useSuspenseQuery(GET_PROVINCES)
  const { data: { cities } } = useSuspenseQuery(GET_CITIES)
  const [locationOptions, setLocationOptions] = useState<LocationOption[]>([])
  const [compactedLocations, setCompactedLocations] = useState<LocationOption[]>([])

  const allLocationOptions = [...convertProvincesToLocations(provinces), ...convertCitiesToLocations(cities)]
  const fuse = new Fuse(allLocationOptions, { keys: ['name'], threshold: 0.3 })
  const [filteredLocationOptions, setFilteredLocationOptions] = useState(fuse.search('Cavite').map(({ item }) => item))

  useEffect(() => {
    const newCompactedLocations = compactLocations(locations, cities, provinces)
    const selectedLocationIds = newCompactedLocations.flatMap(({ id, locationId, locationType }) => {
      if (locationType === 'province') {
        const cityIdsOfProvince = allLocationOptions.filter(({provinceId}) => provinceId === id).map(({locationId}) => locationId)
        return [...cityIdsOfProvince, locationId]
      } else {
        return locationId
      }
    })
    const updatedOptions = filteredLocationOptions.filter(({locationId}) => !selectedLocationIds.includes(locationId))
    setLocationOptions(updatedOptions)
    setCompactedLocations(newCompactedLocations)
  }, [locations, provinces, cities, filteredLocationOptions])

  function convertLocations(locations: LocationOption[]) {
    const cityIds = locations.flatMap((loc) => {
      if (loc.locationType === 'province') {
        return cities.filter(({ provinceId }) => loc.id === provinceId).map(({ id }) => id)
      } else {
        return loc.id
      }
    })
    return uniq(cityIds)
  }

  function handleLocationDelete(location: LocationOption) {
    onLocationsChange(compactedLocations.filter(({ id: lid }) => lid !== location.id))
    setLocationOptions((prev) => {
      let updatedLocations = [...prev, location]
      if (location.locationType === 'province') {
        const citiesOfProvince = cities.filter(({ provinceId }) => provinceId === location.id)
        updatedLocations = [...updatedLocations, ...convertCitiesToLocations(citiesOfProvince)]
      }
      return uniqBy(updatedLocations, 'locationId')
    })

    let removedLocationIds = [location.id]
    if (location.locationType === 'province') {
      const citiesOfProvince = cities.filter(({ provinceId }) => provinceId === location.id)
      removedLocationIds = [location.id, ...citiesOfProvince.map(({id}) => id)]
    }
    const updatedLocations = locations.filter(({id}) => !removedLocationIds.includes(id))
    onCityIdsChange(convertLocations((updatedLocations)))
  }

  return (
    <Box>
      <Autocomplete
        key={`locations#${locations.length}`}
        sx={{ '--Input-focusedThickness': 0 }}
        autoFocus={autoFocus}
        placeholder="City or province name"
        onInputChange={(_, value) => setFilteredLocationOptions(fuse.search(value).map(({ item }) => item))}
        onChange={(_, value) => {
          if(value) {
            const updatedLocations = [...compactedLocations, value]

            onCityIdsChange(convertLocations(updatedLocations))
            onLocationsChange(updatedLocations)

            setLocationOptions((prev) => {
              let updatedLocationOptions = prev.filter(({ locationId }) => {
                return locationId !== value.locationId
              })
              if (value.locationType === 'province') {
                updatedLocationOptions = updatedLocationOptions.filter(({ provinceId }) => provinceId !== value.id)
              }
              return updatedLocationOptions
            })
          }
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id && option.locationType === value.locationType
        }}
        options={locationOptions}
        noOptionsText="Location not found or already added"
      />
      <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {compactedLocations.length ?
          compactedLocations.map((location) => (
            <Chip
              key={location.locationId}
              sx={{ p: '7px 15px' }}
              endDecorator={
                <ChipDelete
                  color="neutral"
                  variant="plain"
                  onClick={() => handleLocationDelete(location)}
                >
                  <CloseIcon sx={{ fontSize: '16px' }} />
                </ChipDelete>
              }
            >
              {location.name}
            </Chip>
        ))
        : <Box sx={{ fontSize: fontSizes.sm }}>No locations selected</Box>}
      </Box>
    </Box>
  )
}
