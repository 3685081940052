//@ts-expect-error: This is needed by Rails
import React, { useContext } from 'react'
import { Box, Button, Divider, IconButton, Tooltip } from '@mui/joy'
import { fontSizes, colors } from '../layouts/Theme'
import JobType from '../types/job-type'
import { useState, useRef } from 'react'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate } from 'react-router-dom'
import ImagesPreview from './ImagesPreview'
import DisplayMinMax from './DisplayMinMax'
import DisplayTimeline from './DisplayTimeline'
import SendQuoteForm from './SendQuoteForm'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import CategoryIcon from './CategoryIcon'
import DeleteIcon from '@mui/icons-material/Delete'
import { useMutation } from '@apollo/client'
import { CREATE_DISCARDED_LEAD } from '../graphql/lead-queries'
import { isEmpty } from 'lodash'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
dayjs.extend(relativeTime)

export default function LeadCard({ job, discarded = false, preview = false }: { job: JobType, discarded?: boolean, preview?: boolean }) {
  const navigate = useNavigate()
  const { id, uuid, summary, budgetMin, budgetMax, createdAt, timeline, description, images, jobCategory, categoryAttrsValues, estimatesCount } = job
  const [estimateSubmitted, setEstimateSubmitted] = useState(false)
  const [recentlyDiscarded, setRecentlyDiscarded] = useState(false)
  const [showQuoteForm, setShowQuoteForm] = useState(false)
  const cardRef = useRef<HTMLElement>(null)
  const { showAlert } = useContext(LayoutContext)
  const [createDiscardedLead, { loading: creatingDiscardedLead }] = useMutation(CREATE_DISCARDED_LEAD, {
    variables: { jobId: job.id },
    onCompleted: ({ createDiscardedLead: { errors }}) => {
      if (errors.length) {
        showAlert(errors.join('\n\n'), 'danger')
      } else {
        setRecentlyDiscarded(true)
      }
    }
  })

  function handleGiveEstimateClick() {
    setShowQuoteForm(true)
    cardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  function onQuoteSubmitted() {
    setEstimateSubmitted(true)
    setShowQuoteForm(false)
    showAlert("Your estimate was sent!")
  }

  return (
    <Box
      ref={cardRef}
      key={id}
      sx={{
        scrollMarginTop: '20px',
        backgroundColor: '#FFF',
        borderRadius: '20px',
        p: '20px',
        pb: preview ? '10px' : '20px',
        mb: 2,
        position: 'relative',
      }}
    >
        <Box sx={{ display: 'flex' }}>
          <CategoryIcon categorySlug={jobCategory.slug} />
          <Box>
            <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600, color: recentlyDiscarded || discarded ? colors.gray3 : colors.fontColor }}>
              {summary}
            </Box>
            <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2, mt: '-2px' }}>
              Posted {dayjs(createdAt).fromNow()}
            </Box>
          </Box>
        </Box>
        {!recentlyDiscarded &&
          <Box sx={{ mt: 2, fontSize: fontSizes.sm, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '12px', height: '30px' }}>
              <RequestQuoteIcon sx={{ mr: '5px', fontSize: '20px', color: colors.gray1 }} />Estimates Received: {estimatesCount}
            </Box>
            <DisplayMinMax valueMin={budgetMin} valueMax={budgetMax} />
            <DisplayTimeline timeline={timeline} />
          </Box>}
        {!recentlyDiscarded && <ImagesPreview images={images} />}
        {!showQuoteForm && !recentlyDiscarded && !preview &&
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {estimateSubmitted ?
                <Box sx={{ color: colors.gray2 }}>
                  <span>Estimate submitted</span>
                  <MarkEmailReadIcon sx={{ ml: '5px', color: colors.green }}/>
                </Box> :
                <Button
                  color={discarded ? 'neutral' : 'primary'}
                  variant={discarded ? 'outlined' : 'solid'}
                  onClick={() => handleGiveEstimateClick()}>Give Estimate</Button>}
                {!isEmpty(categoryAttrsValues) || description && <Button variant="outlined" color="neutral" sx={{ ml: 1 }} onClick={() => navigate(`/business/leads/${uuid}`)}>More Details</Button>}
            </Box>
            {!discarded &&
              <Tooltip variant="solid" title="Discard this lead">
                <IconButton color="neutral" variant="outlined" onClick={() => createDiscardedLead()} disabled={creatingDiscardedLead}>
                  <DeleteIcon sx={{ color: colors.gray3, fontSize: '18px' }}/>
                </IconButton>
              </Tooltip>}
          </Box>}
        {recentlyDiscarded && <Box sx={{ mt: 1, color: colors.gray2, fontSize: fontSizes.sm }}>This lead has been discarded</Box>}
      {showQuoteForm &&
        <>
          <Divider sx={{ mt: 3 }} />
          <Box mt={2}>
            <SendQuoteForm job={job} onCompleted={onQuoteSubmitted} onClose={() => setShowQuoteForm(false)} />
          </Box>
        </>}
    </Box>
  )
}
