//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import { GET_JOBS } from '../../graphql/job-queries'
import { useSuspenseQuery } from '@apollo/client'
import Box from '@mui/joy/Box'
import { colors } from '../../layouts/Theme'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button } from '@mui/joy'
import MarkAsCompletedModal from '../../components/MarkAsCompletedModal'
import WorkIcon from '@mui/icons-material/Work'
import AddBusinessReviewModal from '../../components/AddBusinessReviewModal'
import EstimateType from '../../types/estimate-type'
import JobCard from '../../components/JobCard'
dayjs.extend(relativeTime)

export default function JobsPage() {
  const { data: { jobs } } = useSuspenseQuery(GET_JOBS)
  const navigate = useNavigate()
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [reviewModalOpen, setReviewModalOpen] = useState(false)
  const [winningEstimate, setWinningEstimate] = useState<EstimateType | null>(null)

  function openModal(jobId: string) {
    setSelectedJobId(jobId)
    setModalOpen(true)
  }

  function onJobCompleted(winningEstimate: EstimateType) {
    setWinningEstimate(winningEstimate)
    setReviewModalOpen(true)
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}> Jobs
        </Typography>
      </Grid>
      {selectedJobId && <MarkAsCompletedModal open={modalOpen} setOpen={setModalOpen} jobId={selectedJobId!} onJobCompleted={onJobCompleted} />}
      {winningEstimate &&
        <AddBusinessReviewModal open={reviewModalOpen} setOpen={setReviewModalOpen} businessUser={winningEstimate.businessUser} jobId={winningEstimate.jobId} />}
      <Grid xs={12} md={9} lg={6}>
        {jobs.length ?
          jobs.map((job) => {
            return (
              <JobCard key={job.id} job={job} onCompleted={openModal} />
            ) 
        }) :
        <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ textAlign: 'center' }}>
            <WorkIcon sx={{ fontSize: '30px', color: colors.gray2 }}/>
            <p>You have not added any jobs yet</p>
            <Button variant="outlined" color="neutral" onClick={() => navigate('/customer/get-estimates')}>Post a Job</Button>
          </Box>
        </Box>}
      </Grid>
    </Grid>
  )
}
