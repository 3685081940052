import { Grid, IconButton } from '@mui/joy'
import { GET_CURRENT_BUSINESS_REVIEW } from '../../graphql/review-queries'
import { useSuspenseQuery } from '@apollo/client'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { BusinessReview } from '../../components/BusinessReviews'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

export default function BusinessReviewPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: { currentBusinessReview } } = useSuspenseQuery(GET_CURRENT_BUSINESS_REVIEW, { variables: { id: id! }})
  const fromNotifications = searchParams.get('ref') === 'notifications'

  return (
    <Grid container>
      <Grid xs={12}>
        <IconButton
          sx={{ mb: 2, pl: '5px', pr: '10px' }}
          onClick={() => navigate(fromNotifications ? '/business/notifications' : '/business/reviews')}
        >
          <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12} md={6}>
        <BusinessReview businessReview={currentBusinessReview} />
      </Grid>
    </Grid>
  )
}
