import { Box, Typography } from '@mui/joy'
import { ConstructionJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function ConstructionDetails({ attrs }: { attrs: ConstructionJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Lot Area</Typography>
        <Typography level="title-sm">{attrs.lotArea}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Floor Area</Typography>
        <Typography level="title-sm">{attrs.floorArea}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Number of Floors</Typography>
        <Typography level="title-sm">{attrs.numberOfFloors}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Type of Finish</Typography>
        <Typography level="title-sm">{attrs.typeOfFinish}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Blueprint or floor plan exists?</Typography>
        <Typography level="title-sm">{attrs.hasBlueprint}</Typography>
      </Box>
    </Box>
  )
}
