//@ts-expect-error: This is needed by Rails
import React from 'react'
import Box from '@mui/joy/Box'
import { fontFamilies, colors } from '../layouts/Theme'
import JobCategoryType from '../types/job-category-type'
import { renderIcon } from '../utils/render-utils'

type CategoryCardProps = {
  jobCategory: JobCategoryType,
  selected: boolean,
  onClick: (jobCategory: JobCategoryType | null) => void
}

export default function CategoryCard({ jobCategory, selected = false, onClick }: CategoryCardProps) {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: { xs: '15px', md: '20px', lg: '25px' },
        borderRadius: '20px',
        height: { xs: '170px', md: '190px', lg: '210px' },
        '&:hover': {
          outline: `2px solid ${colors.primary}`,
          cursor: 'pointer',
        },
        overflow: 'hidden',
        outline: selected ? `2px solid ${colors.primary}` : 'none',
      }}
      onClick={() => { onClick(jobCategory) }}
    >
      <Box sx={{ color: colors.primary, textAlign: 'left', marginBottom: { xs: 1, md: 2, lg: 3 } }}>
        {renderIcon(jobCategory.slug)}
      </Box>
      <Box sx={{ fontFamily: fontFamilies.title, fontSize: { xs: '16px', md: '20px' }, fontWeight: 700 }}>
        {jobCategory.name}
      </Box>
      <Box sx={{ color: colors.gray2, fontSize: { xs: '12px', md: '14px' } }}>
        {jobCategory.description}
      </Box>
    </Box>
  )
}
