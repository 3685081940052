import { useSuspenseQuery } from '@apollo/client'
import { IconButton, Grid, Box } from '@mui/joy'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_BUSINESS_REVIEWS } from '../../graphql/review-queries'
import BusinessReviews from '../../components/BusinessReviews'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { VIEW_BUSINESS_PROFILE } from '../../graphql/business-profile-queries'
import BusinessHeader from '../../components/BusinessHeader'

export default function JobBusinessReviewsPage() {
  const { jobId, businessUserId } = useParams()
  const { data: { viewBusinessProfile: businessProfile } } = useSuspenseQuery(VIEW_BUSINESS_PROFILE, { variables: { businessUserId: businessUserId! } })
  const { data: { businessReviews } } = useSuspenseQuery(GET_BUSINESS_REVIEWS, { variables: { jobId: jobId!, businessUserId: businessUserId! } })
  const navigate = useNavigate()

  return (
    <Grid container>
      <Grid xs={12}>
        <IconButton
          sx={{ mb: 2, pl: '5px', pr: '10px' }}
          onClick={() => navigate(`/customer/job/${jobId}`)}>
          <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12} md={7} sx={{ mb: 2 }}>
        <Box sx={{ p: '20px', pb: '10px', backgroundColor: '#FFF', borderRadius: '10px', display: 'flex' }}>
          <BusinessHeader businessProfile={businessProfile} hideReviews />
        </Box>
      </Grid>
      <Grid xs={12} md={7}>
        <BusinessReviews businessReviews={businessReviews} />
      </Grid>
    </Grid>
  )
}
