//@ts-expect-error: This is needed by Rails
import React, { Suspense } from "react"
import Box from '@mui/joy/Box'
import { Outlet } from 'react-router-dom'
import CustomerSidebar from './Sidebar'
import { colors } from '../Theme'
import CustomerContextProvider from './CustomerContextProvider'
import LayoutContextProvider from "../LayoutContextProvider"
import Alert from "../Alert"
import Header from "../Header"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../../pages/ErrorFallback"
import LoadingScreen from "../LoadingScreen"
import Sidebar from "../Sidebar"
import CustomerMenu from "../../components/CustomerMenu"

export default function CustomerLayout() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LayoutContextProvider>
        <CustomerContextProvider>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Sidebar label="Customer">
              <CustomerMenu />
            </Sidebar>
            <Box
              sx={{
                width: '100%',
                paddingTop: { xs: '0', sm: '10px' },
                backgroundColor: colors.background,
              }}
            >
              <Header />
              <Box sx={{ p: { xs: '20px', sm: '30px' }}}>
                <Alert />
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </Box>
            </Box>
          </Box>
        </CustomerContextProvider>
      </LayoutContextProvider>
    </ErrorBoundary>
  )
}
