//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import { DialogContent, DialogTitle, Divider, Button, FormControl, FormLabel, Modal, ModalDialog, Option, Select, Box, Input, Grid } from "@mui/joy"
import { fontSizes } from '../layouts/Theme'
import { Typography } from '@mui/material'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  onConfirm: (email: string) => void
  disableButton: boolean
}
export default function ConfirmAccountDeletionModal({ open, setOpen, onConfirm, disableButton }: Props) {
  const [email, setEmail] = useState('')

  return (
    <Modal open={open} onClose={() => setOpen(false)} disableRestoreFocus>
      <ModalDialog>
        <DialogTitle sx={{ fontSize: fontSizes.regular }}>
          Confirm Account Deletion
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>Are you sure you want to permanently delete your account? All your data in HomeMaster will be deleted forever. To confirm this action, please type your email address below and click the confirmation button.</Typography>
          <Typography>You will be logged out of the system once the account deletion succeeded.</Typography>
          <FormControl sx={{ mt: 1 }}>
            <FormLabel>Confirm Email Address</FormLabel>
            <Input type="text" autoFocus value={email} onChange={({ target: { value }}) => setEmail(value)} />
          </FormControl>
          <Grid container sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Button sx={{ width: { xs: '100%', md: 'auto' } }} color="danger" disabled={disableButton} onClick={() => onConfirm(email)}>
                Permanently Delete My Account
              </Button>
            </Grid>
            <Grid xs={12} md={6} textAlign="right">
              <Button sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 1, md: 0 } }} color="neutral" variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </ModalDialog>
    </Modal>
  )
}
