import { Box, FormControl, FormLabel, Input, Select, Option } from "@mui/joy";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { ConstructionJobAttributes, JobAttributes } from "../../types/job-type";

export default function ConstructionForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: ConstructionJobAttributes
}) {
  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl>
        <FormLabel>Lot area (sqm)</FormLabel>
        <Input {...register('categoryAttrsValues.lotArea')} defaultValue={attrs?.lotArea} required type="number" placeholder="Size of your lot e.g. 120" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Floor area (sqm)</FormLabel>
        <Input {...register('categoryAttrsValues.floorArea')} defaultValue={attrs?.floorArea} required type="number" placeholder="Your desired floor area e.g. 200" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Number of floors</FormLabel>
        <Input {...register('categoryAttrsValues.numberOfFloors')} defaultValue={attrs?.numberOfFloors} required type="number" placeholder="How many floors? e.g. 2" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Type of Finish</FormLabel>
        <Controller
          name="categoryAttrsValues.typeOfFinish"
          defaultValue={attrs?.typeOfFinish}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Your desired finish"
            >
              <Option value="Basic">Basic</Option>
              <Option value="Standard">Standard</Option>
              <Option value="Premium">Premium</Option>
              <Option value="Luxury">Luxury</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Do you have a blueprint or floor plan?</FormLabel>
        <Controller
          name="categoryAttrsValues.hasBlueprint"
          defaultValue={attrs?.hasBlueprint}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Blueprint or floor plan"
            >
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
