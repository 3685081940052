//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box } from '@mui/joy'
import { JobTimeline } from '../types/job-type'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { colors } from '../layouts/Theme'

const timelineMap = {
  [JobTimeline.asap]: "As soon as possible",
  [JobTimeline.inWeeks]: "1-3 weeks from now",
  [JobTimeline.inMonths]: "1-3 months from now",
  [JobTimeline.inHalfYear]: "6-12 months from now",
}

function formatTimeline(timeline: JobTimeline) {
  return timelineMap[timeline]
}

export default function DisplayTimeline({ timeline }: { timeline: JobTimeline }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '8px', height: '30px' }}>
      <AccessTimeIcon sx={{ mr: '5px', fontSize: '20px', color: colors.gray1 }}/>{formatTimeline(timeline)}
    </Box>
  )
}
